import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, TablePagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as actions from '../../redux/actions';

export default function MileageChangeHistory() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const vehicle = useSelector((state) => state.vehicle);
  const mileageTransaction = useSelector((state) => state.MileageTracsaction);
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  console.log('Test mileage', mileageTransaction);
  useEffect(() => {
    dispatch(actions.mileageTransactionAll({ vehicle: id, page, size }));
    return () => {};
  }, [page, size]);
  useEffect(() => {
    setTotal(mileageTransaction?.total);
    return () => {};
  }, [mileageTransaction]);

  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {' '}
                  <h1 className="font-bold">ครั้งที่</h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">วันที่เปลี่ยน</h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">เลขไมล์</h1>
                </TableCell>
                {/* <TableCell align="center">
                  <h1 className="font-bold">รายละเอียด</h1>
                </TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {/* {console.log('Refuel', refuelTransition)} */}
              {_.map(mileageTransaction.rows, (eachData, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {dayjs(eachData?.createdAt).format(
                      'DD/ MM/ BBBB  เวลา HH:mm',
                    )}
                  </TableCell>
                  <TableCell align="center">{eachData?.mileage}</TableCell>
                  {/* <TableCell align="center"></TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
}
