/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import thLocale from 'date-fns/locale/th';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Button, Card, CardContent, TextField } from '@mui/material';
import { ArrowLeft, ArrowRight } from 'react-feather';
import _ from 'lodash';

import { currencyFormatter, dayjs } from '../../utils/functions';
import * as actions from '../../redux/actions';
import { MaintenVehicleMonthly } from '../../components/Report';
import { MaintenanceOrderTable } from '../../components/Table';

function MaintenanceOrderContainer({ vehicleId, information, vehicleDetail }) {
  const dispatch = useDispatch();
  const maintenanceOrder = useSelector((state) => state.maintenanceOrders);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [startDate, setStartDate] = useState(dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState(dayjs().toDate());

  const history = useHistory();
  const maintenanceOrders = _.map(maintenanceOrder?.rows, (topic) => ({
    ...topic,
    outer_services: _.map(topic?.outer_services, (eachOuterService) => ({
      ...eachOuterService,
      name: eachOuterService?.title,
    })),
    order: _.union(topic?.outer_services, topic?.maintenance_topic),
  }));
  console.log('maintenanceOrders', maintenanceOrders);
  const handlePrint = () => {
    try {
      MaintenVehicleMonthly(maintenanceOrder?.rows, information, vehicleDetail);
    } catch (error) {
      alert('ระบบปริ้นมีปัญหา โปรดติดต่อผู้ดูแลระบบ');
    }
  };

  useEffect(() => {
    dispatch(
      actions.maintenanceOrderAll({
        name: '',
        vehicle: vehicleId,
        page,
        size,
        includeHistory: true,
        start: dayjs(startDate).format('YYYY-MM-DD'),
        end: dayjs(endDate).format('YYYY-MM-DD'),
      }),
    );
  }, [page, size, startDate, endDate]);

  useEffect(() => {
    setTotal(maintenanceOrder?.total);
    return () => {};
  }, [maintenanceOrder]);

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handlePushToDetail = (orderId) => {
    history.push(`/cmms/maintenance-orders/detail/${orderId}`);
  };

  const minusOneMonth = () => {
    const previousMonth = dayjs(startDate)
      .subtract(1, 'month')
      .startOf('month');
    const endOfPreviousMonth = dayjs(startDate)
      .subtract(1, 'month')
      .endOf('month');
    setStartDate(previousMonth);
    setEndDate(endOfPreviousMonth);
  };

  const addOneMonth = () => {
    const previousMonth = dayjs(startDate).add(1, 'month').startOf('month');
    const endOfPreviousMonth = dayjs(startDate).add(1, 'month').endOf('month');
    setStartDate(previousMonth);
    setEndDate(endOfPreviousMonth);
  };

  return (
    <div>
      <div className="my-2">
        <Card>
          <CardContent>
            <div className="flex gap-2 ">
              <div className="w-full lg:w-1/4">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={thLocale}
                >
                  <DatePicker
                    views={['year', 'month', 'day']}
                    label="เริ่มต้นค้นหา"
                    minDate={new Date('2012-03-01')}
                    maxDate={new Date('2030-06-01')}
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                      //   setDateChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} size={'small'} />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="w-full lg:w-1/4">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={thLocale}
                >
                  <DatePicker
                    views={['year', 'month', 'day']}
                    label="สิ้นสุดค้นหา"
                    minDate={new Date('2012-03-01')}
                    maxDate={new Date('2030-06-01')}
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                      // setMonthChang(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} size={'small'} />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <div className="flex justify-end w-full">
                <Button variant="contained" onClick={() => handlePrint()}>
                  พิมพ์
                </Button>
              </div>
            </div>
            <div className="w-full lg:w-1/2 my-2 flex gap-1">
              <Button
                variant="outlined"
                startIcon={<ArrowLeft />}
                onClick={minusOneMonth}
              >
                กลับ 1 เดือน
              </Button>
              <Button
                variant="outlined"
                endIcon={<ArrowRight />}
                onClick={addOneMonth}
              >
                เพิ่ม 1 เดือน
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="my-2">
        <Card>
          <CardContent>
            <div className="font-semibold">สรุปค่าใช้จ่าย</div>
            <div className="flex flex-wrap">
              <div className="w-1/2 lg:w-1/4 my-2">
                <div>ค่าใช้จ่ายทั้งหมด</div>
                <div className="text-lg">
                  {currencyFormatter.format(
                    _.sumBy(
                      maintenanceOrder?.analyzed,
                      (row) => row?.total_price,
                    ),
                  )}{' '}
                  บาท
                </div>
              </div>
              <div className="w-1/2 lg:w-1/4 my-2">
                <div>ค่าแรงในการบำรุงรักษา / ค่าบำรุงรักษา</div>
                <div className="text-lg">
                  {currencyFormatter.format(
                    _.sumBy(
                      maintenanceOrder?.analyzed,
                      (row) => row?.total_maintenance_topic,
                    ),
                  )}{' '}
                  บาท
                </div>
              </div>
              <div className="w-1/2 lg:w-1/4 my-2">
                <div>ค่าอะไหล่</div>
                <div className="text-lg">
                  {currencyFormatter.format(
                    _.sumBy(
                      maintenanceOrder?.analyzed,
                      (row) => row?.total_material_price,
                    ),
                  )}{' '}
                  บาท
                </div>
              </div>
              <div className="w-1/2 lg:w-1/4 my-2">
                <div>ค่าแรงภายนอก (อะไหล่นอก)</div>
                <div className="text-lg">
                  {currencyFormatter.format(
                    _.sumBy(
                      maintenanceOrder?.analyzed,
                      (row) => row?.total_outer_service_price,
                    ),
                  )}{' '}
                  บาท
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="my-2">
        <MaintenanceOrderTable
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          size={size}
          total={total}
          maintenanceOrders={maintenanceOrder?.rows}
          page={page}
          showCommandButton={true}
          showSuccessDate={false}
          handlePushToDetail={handlePushToDetail}
          showAllPrice
          showMaintenanceLevel={
            information?.setting?.cmms?.show_maintenance_level
          }
          detailLink="/cmms/maintenance-orders/detail"
          showMaintenanceType={
            information?.setting?.cmms?.show_maintenance_type
          }
        />
      </div>
    </div>
  );
}

MaintenanceOrderContainer.propTypes = {
  vehicleId: PropTypes.string,
  information: PropTypes.object,
  trailerTailId: PropTypes.string,
};

export default MaintenanceOrderContainer;
