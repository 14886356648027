/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import * as actions from '../../redux/actions';
import { VehicleForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { CSVUploadModal } from '../../components/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};
const defaultValue = { licence_number: '', type: '' };

function Vehicles({ title, subtitle }) {
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicle);
  const vehicletype = useSelector((state) => state.vehicletype);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [FileOpen, setFileOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset(defaultValue);
    setOpen(false);
  };
  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  useEffect(() => {
    dispatch(actions.vehicleAll({ name, page, size }));
    dispatch(actions.vehicletypeAll({ size: null }));
  }, [name, page, size]);

  useEffect(() => {
    setTotal(vehicle?.total);
    return () => {};
  }, [vehicle]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  console.log(file);
  const onSubmit = async (data) => {
    try {
      const dataSubmit = {
        ...data,
        file: _.isEmpty(file) ? undefined : file[0],
      };
      // console.log(dataSubmit);
      await dispatch(actions.vehicleCreate(dataSubmit));
      reset(defaultValue);
      alert('สำเร็จ');
      handleClose();
      await dispatch(actions.vehicleAll({ name, page, size }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      try {
        await dispatch(actions.vehicleCreate({ arr: csvData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(actions.vehicleAll({ name, page, size }));
      } catch (error) {
        alert('เพิ่มรถไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการรถได้');
    }
  };

  const handleDeleteVehicle = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.vehicleDelete(id));
        await dispatch(actions.vehicleAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToEditVehicle = (id) => {
    history.push(`vehicle/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderAddButton = () => (
    <div className="self-center ">
      <div className="flex flex-row justify-end pb-4 gap-1">
        <Button variant="contained" onClick={handleOpen}>
          เพิ่ม
        </Button>
        <Button variant="contained" onClick={handleCSVOpen} color="success">
          อัพโหลด
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl max-h-screen ">
          <div className="py-2 h-96 overflow-y-scroll">
            <div className="font-semibold text-lg">เพิ่มข้อมูล</div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VehicleForm
                FileOpen={FileOpen}
                control={control}
                Controller={Controller}
                errors={errors}
                vehicle={vehicle}
                vehicletype={vehicletype}
                watch={watch}
                setValue={setValue}
                file={file}
                setFile={setFile}
                setFileOpen={setFileOpen}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderCSVUploadModal = () => (
    <CSVUploadModal
      csvData={csvData}
      setCsvData={setCsvData}
      fileTemplateURL="/filetemplate/Vehicle.csv"
      handleAddFromFile={handleAddFromFile}
      handleClose={handleCSVClose}
      isOpen={csvUploadOpen}
      titleName="รถ"
      typeRows={vehicletype}
    />
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ป้ายทะเบียน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> คนขับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ประเภทรถ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ข้อมูลรถ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(vehicle.rows) ? (
                vehicle.rows.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div className="text-lg font-semibold font-sans">
                        {`${row?.licence_number}`}
                      </div>
                    </TableCell>
                    <TableCell>
                      {row?.driver?.employee?.firstname || '-'}{' '}
                      {row?.driver?.employee?.lastname || ' '}
                      <br />
                      {row?.driver?.employee?.nickname
                        ? `(${row?.driver?.employee?.nickname})`
                        : ' '}
                    </TableCell>
                    <TableCell>{`${row?.type?.name}`}</TableCell>
                    <TableCell>
                      <div className="text-sm">
                        ยี่ห้อ {row?.attribute?.brand || '-'}{' '}
                        {row?.attribute?.generation || ''}
                      </div>
                      <div className="text-sm">
                        เลขคัชชี {row?.attribute?.chassis_number || '-'}
                        {'    '}
                        เลขตัวถัง {row?.attribute?.body_number || ''}
                      </div>
                      <div
                        className={`text-sm ${
                          dayjs(row?.attribute?.insurance_end).diff(
                            dayjs(),
                            'day',
                          ) <= 30
                            ? 'text-red-500 font-semibold'
                            : ''
                        }`}
                      >
                        หมดประกัน{' '}
                        {row?.attribute?.insurance_end
                          ? dayjs(row?.attribute?.insurance_end).format(
                              'D MMM BBBB',
                            )
                          : ' - '}
                      </div>
                      <div
                        className={`text-sm ${
                          dayjs(row?.attribute?.invoice_date).diff(
                            dayjs(),
                            'day',
                          ) <= 30
                            ? 'text-red-500 font-semibold'
                            : ''
                        }`}
                      >
                        ต้องต่อ พรบ.{' '}
                        {row?.attribute?.invoice_date
                          ? dayjs(row?.attribute?.invoice_date).format(
                              'D MMM BBBB',
                            )
                          : '-'}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Link to={`vehicle/detail/${row?._id}`}>
                          <Button
                            variant="contained"
                            color={'info'}
                            size={'small'}
                          >
                            รายละเอียด
                          </Button>{' '}
                        </Link>
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditVehicle(row._id)}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteVehicle(row?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          //
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (vehicle.isLoading) {
    return <Loading />;
  }
  if (!vehicle.isLoading && vehicle.isCompleted) {
    return (
      <div>
        {renderCSVUploadModal()}
        {renderModal()}
        <div className="flex justify-between">
          {renderTitle()}
          {renderAddButton()}
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

Vehicles.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Vehicles.defaultProps = {
  title: '',
  subtitle: '',
};

export default Vehicles;
