/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { Card, TextField, InputAdornment, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { ViewTitle } from '../../components/ViewTitle';
import { MaintenanceOrderTable } from '../../components/Table';
import { MaintenanceOrderReport } from '../../components/Report';

import { BackButton } from '../../components/Button';
import ExportExcelHistory from '../../components/Excel/ExportExelHistory';
import Historyall from '../../utils/modelutils/Historyall';
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function DetailMaintenanceHistory({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const maintenanceOrder = useSelector((state) => state.maintenanceOrders);
  const information = useSelector((state) => state.information);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [name, setName] = useState('');
  const [total, setTotal] = useState();

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  useEffect(() => {
    dispatch(
      actions.maintenanceOrderAll({
        name,
        page,
        size,
        includeHistory: true,
        start: dayjs(id).startOf('month').format('YYYY-MM-DD'),
        end: dayjs(id).endOf('month').format('YYYY-MM-DD'),
      }),
    );
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    if (maintenanceOrder?.rows) {
      setTotal(maintenanceOrder?.total);
    }

    return () => {};
  }, [maintenanceOrder]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => (
    <ViewTitle
      title={`${title} ${dayjs(id).format('MMMM / BBBB')}`}
      subtitle={subtitle}
    />
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderDashboardButton = () => (
    <div className="self-center">
      <Link to={`/cmms/monthy-maintenance-analysis/${id}`}>
        <Button color="info" variant="contained">
          แดชบอร์ดวิเคราะห์
        </Button>
      </Link>
      <div className="my-auto ml-2 p-4 py-2">
        <ExportExcelHistory
          columnList={Historyall?.columns}
          currentData={maintenanceOrder?.rows}
          dataAPIEndPoint="maintenance-order"
          disableAllExport
          dataQuery={{
            page: 1,
          }}
          sheetName="LOGS"
        />
      </div>
    </div>
  );

  const renderTable = () => (
    <div className="my-2">
      <MaintenanceOrderTable
        maintenanceOrders={maintenanceOrder?.rows}
        showCommandButton={true}
        showModifyMethod
        showCause
        showAllPrice
        page={1}
        size={size}
        total={total}
        showMaintenanceType={information?.setting?.cmms?.show_maintenance_type}
        showMaintenanceLevel={
          information?.setting?.cmms?.show_maintenance_level
        }
        detailLink="../../maintenance-orders/detail"
        useSparePart={information?.setting?.cmms?.use_spared_part}
        showSuccessDate={true}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );

  if (maintenanceOrder.isLoading) {
    return <Loading />;
  }

  if (!maintenanceOrder.isLoading && maintenanceOrder.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          {renderDashboardButton()}
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
}

DetailMaintenanceHistory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
