import React, { useState } from 'react';
import {
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  createFilterOptions,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useParams } from 'react-router';
import { useForm, Controller } from 'react-hook-form';

import { MATERIAL_TRANSACTION_TYPE } from '../../../utils/constants';
import dayjs from '../../../utils/functions/thDayjs';
import CustomerForm from '../CRM/CustomerForm';
import { DatePicker } from '../../Datepicker';

export function MaterialStockForm({
  errors,
  Controller,
  control,
  place,
  materialTransactionType,
  customer,
  department,
  me,
  type,
  setType,
  setFilterPlace,
  setSelectMaterial,
  setSelectMaterialLot,
  customerType,
  handleCreateCustomer,
}) {
  const { id } = useParams();
  const information = useSelector((state) => state.information);
  const {
    setting: { ims: imsSetting },
  } = information;

  const {
    handleSubmit: createCustomerHandleSubmit,
    formState: { errors: createCustomerError },
    control: createCustomerControl,
  } = useForm();
  const filter = createFilterOptions();

  const [isModalCreateCustomerOn, setIsModalCreateCustomerOn] = useState(false);
  const [createdCustomerName, setCreatedCustomerName] = useState('');

  const checkType = (data) => {
    const findType = _.find(materialTransactionType, { _id: data });
    setType(findType);
    setSelectMaterial([]);
    setSelectMaterialLot([]);
  };

  const checkPlace = (data) => {
    const findPlace = _.find(place, { _id: data });
    setFilterPlace(findPlace);
  };

  const materialOptions = () => {
    if (type?.direction === MATERIAL_TRANSACTION_TYPE[0].direction) {
      return (
        <div className="w-full flex flex-row flex-wrap">
          <div className="w-full px-1 py-2">
            <Controller
              name={'bill_number'}
              control={control}
              defaultValue={''}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="เลขที่บิล"
                  fullWidth
                  size={'small'}
                  helperText={errors.bill_number && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'bill_pickeup_number'}
              control={control}
              defaultValue={''}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="เลขที่ใบส่งของ"
                  fullWidth
                  size={'small'}
                  helperText={errors.bill_number && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            {!_.isEmpty(customer) && (
              <Controller
                name={'supplier'}
                control={control}
                rules={{ required: false }}
                defaultValue={customer[0]}
                render={({ field }) => (
                  <Autocomplete
                    defaultValue={field?.value}
                    size="small"
                    {...field}
                    options={_.map(customer, (eachCustomer, index) => ({
                      ...eachCustomer,
                      index: index + 1,
                    }))}
                    getOptionLabel={(option) => {
                      // Input Case
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option?.inputValue) {
                        return `${option?.inputValue || ''}`;
                      }
                      // Default case
                      return `${option?.index || ' '} ${option?.name}`;
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          fontSize: 15,
                          '& > span': { mr: '10px', fontSize: 18 },
                        }}
                        {...props}
                        key={`cus-${option?.index}`}
                      >
                        {option?.index || ' '} {option?.name}
                      </Box>
                    )}
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        setTimeout(() => {
                          setIsModalCreateCustomerOn(true);
                          setCreatedCustomerName(newValue);
                        });
                      } else if (newValue && newValue?.inputValue) {
                        setIsModalCreateCustomerOn(true);
                        setCreatedCustomerName(newValue?.inputValue);
                      }
                      // Default Case
                      else {
                        field.onChange(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params?.inputValue !== '') {
                        filtered.push({
                          inputValue: params?.inputValue,
                          name: `เพิ่ม ${params?.inputValue}  ลงในระบบ`,
                        });
                      }

                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="คู่ค้า" />
                    )}
                  />
                )}
              />
            )}
          </div>

          {imsSetting?.display?.purchase_date && (
            <div className="w-full lg:w-1/2 px-1 my-2">
              <Controller
                name={'purchase_date'}
                control={control}
                defaultValue={new Date()}
                rules={{ required: false }}
                render={({ field }) => (
                  <DatePicker
                    label="วันที่่ซื้อ"
                    setValue={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </div>
          )}
          <div className="w-full lg:w-1/2 px-1 my-2">
            <Controller
              name={'receipt_date'}
              control={control}
              defaultValue={dayjs().format('YYYY-MM-DD')}
              rules={{ required: false }}
              render={({ field }) => (
                <DatePicker
                  label="วันที่รับวัสดุ"
                  setValue={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'recipient_name'}
              control={control}
              rules={{ required: true }}
              defaultValue={me ? `${me?.firstname} ${me?.lastname}` : ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้รับ"
                  fullWidth
                  size={'small'}
                  required
                  helperText={errors.recipient_name && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'order_department'}
              control={control}
              defaultValue={me ? me?.department?._id : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
                  <InputLabel id="type" size={'small'}>
                    แผนก
                  </InputLabel>
                  <Select {...field} label="แผนก" size={'small'} fullWidth>
                    {_.size(department) ? (
                      _.map(department, (_department) => (
                        <MenuItem key={_department._id} value={_department._id}>
                          {_department.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-full px-1 py-2">
            <Controller
              name={'remark'}
              control={control}
              defaultValue={''}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หมายเหตุ"
                  fullWidth
                  size={'small'}
                  multiline={true}
                  rows={3}
                  helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        </div>
      );
    }
    if (type?.direction === MATERIAL_TRANSACTION_TYPE[1].direction) {
      return (
        <div className="w-full flex flex-row flex-wrap my-2">
          <div className="w-full px-1 mb-2">
            <Controller
              name={'issue_date'}
              control={control}
              defaultValue={dayjs().format('YYYY-MM-DD')}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  label="วันที่เบิก"
                  setValue={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'request_name'}
              control={control}
              rules={{ required: true }}
              defaultValue={me ? `${me?.firstname} ${me?.lastname}` : ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้เบิก"
                  fullWidth
                  size={'small'}
                  required
                  helperText={errors.recipient_name && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'request_department'}
              control={control}
              defaultValue={me ? me?.department?._id : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
                  <InputLabel id="type" size={'small'}>
                    แผนกที่ขอเบิก
                  </InputLabel>
                  <Select {...field} label="คู่ค้า" size={'small'} fullWidth>
                    {_.size(department) ? (
                      _.map(department, (_department) => (
                        <MenuItem key={_department._id} value={_department._id}>
                          {_department.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>

          <div className="w-full lg:w-full px-1 py-2">
            <Controller
              name={'remark'}
              control={control}
              defaultValue={''}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หมายเหตุ"
                  fullWidth
                  size={'small'}
                  multiline={true}
                  rows={3}
                  helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        </div>
      );
    }
  };

  const renderCreateCustomerDialog = () => (
    <Dialog
      open={isModalCreateCustomerOn}
      onClose={() => setIsModalCreateCustomerOn(false)}
    >
      <form>
        <DialogTitle>สร้างคู่ค้า / ลูกค้า</DialogTitle>
        <DialogContent>
          <div className=" h-96 overlay-y-auto">
            <CustomerForm
              Controller={Controller}
              control={createCustomerControl}
              customerType={{ rows: customerType }}
              errors={createCustomerError}
              customerName={createdCustomerName}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="my-2">
            <Button
              variant="contained"
              type="button"
              onClick={async (event) => {
                await createCustomerHandleSubmit(handleCreateCustomer)(event);
                setIsModalCreateCustomerOn(false);
              }}
            >
              เพิ่ม
            </Button>
          </div>
        </DialogActions>{' '}
      </form>
    </Dialog>
  );

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full lg:w-1/2">
        {renderCreateCustomerDialog()}
        <div className="w-full px-1 py-2">
          {!_.isEmpty(place) && (
            <Controller
              name={'place'}
              control={control}
              defaultValue={id && id !== 'undefined' ? id : place?.[0]}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  size={'small'}
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  options={place}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="คลัง"
                      required
                      helperText={errors?.place && 'กรุณาระบุคลังวัสดุ'}
                    />
                  )}
                />
              )}
            />
          )}
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'material_transaction_type'}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
                <InputLabel id="type" size={'small'}>
                  ประเภทการดำเนินการ
                </InputLabel>
                <Select
                  {...field}
                  label="ประเภทการดำเนินการ"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    checkType(e.target.value);
                  }}
                >
                  {_.size(materialTransactionType) ? (
                    _.map(
                      materialTransactionType,
                      (_materialTransactionType) => (
                        <MenuItem
                          key={_materialTransactionType._id}
                          value={_materialTransactionType._id}
                        >
                          {_materialTransactionType?.direction === 'desc' ? (
                            <i className="fas mr-2 fa-arrow-circle-left"></i>
                          ) : (
                            <i className="fas mr-2 fa-arrow-circle-right"></i>
                          )}{' '}
                          {_materialTransactionType.name}
                        </MenuItem>
                      ),
                    )
                  ) : (
                    <MenuItem>
                      <MenuItem disabled value="">
                        <em>ไม่มีข้อมูล</em>
                      </MenuItem>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          />
        </div>
      </div>
      <div className="w-full lg:w-1/2">{materialOptions()}</div>
    </div>
  );
}

MaterialStockForm.propTypes = {
  errors: PropTypes.shape({
    material_transaction_type: PropTypes.object,
    bill_number: PropTypes.object,
    supplier: PropTypes.object,
    source: PropTypes.object,
    place: PropTypes.object,
    purchase_date: PropTypes.object,
    receipt_date: PropTypes.object,
    issue_date: PropTypes.object,
    remark: PropTypes.object,
    recipient_name: PropTypes.object,
    order_department: PropTypes.object,
    request_department: PropTypes.object,
    request_name: PropTypes.object,
  }),
  materialTransactionType: PropTypes.object,
  place: PropTypes.object,
  customer: PropTypes.object,
  department: PropTypes.object,
  me: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  setFilterPlace: PropTypes.func,
  setSelectMaterial: PropTypes.func,
  setSelectMaterialLot: PropTypes.func,
  Controller: PropTypes.func,
  control: PropTypes.object,
  customerType: PropTypes.arrayOf(PropTypes.object),
  handleCreateCustomer: PropTypes.func,
};

MaterialStockForm.defaultProps = {
  materialTransactionType: null,
  place: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  filterPlace: null,
};

export default MaterialStockForm;
