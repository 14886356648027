/* eslint-disable no-new */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-template */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/order */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import {
  Card,
  TextField,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import dayjs, { Dayjs } from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { currencyFormatter } from '../../utils/functions';
import _ from 'lodash';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import DounutChart from '../../components/Chart/DounutChart';
import DounutPriceChart from '../../components/Chart/DounutPriceChart';
import BarChartOrder from '../../components/Chart/BarChartOrder';
import Loading from 'components/Loading';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
dayjs.extend(quarterOfYear);

export default function Dashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const maintenanceOrder = useSelector((state) => state.maintenanceOrders);
  const [year, setYear] = useState(new Date());
  const [total, setTotal] = useState();

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  useEffect(() => {
    dispatch(
      actions.maintenanceOrderAll({
        includeHistory: true,
        start: dayjs(year).startOf('year').format('YYYY-MM-DD'),
        end: dayjs(year).endOf('year').format('YYYY-MM-DD'),
        fillter: false,
      }),
    );
    return () => {};
  }, [year]);

  useEffect(() => {
    if (maintenanceOrder?.rows) {
      setTotal(maintenanceOrder?.total);
    }

    return () => {};
  }, [maintenanceOrder]);

  const renderYearSelect = () => (
    <div className="">
      <Card>
        <div className="p-2 ">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['year']}
              label="ปี"
              value={year}
              onChange={(newValue) => {
                setYear(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={null}
                  size="small"
                  fullWidth
                />
              )}
            />{' '}
          </LocalizationProvider>
        </div>
      </Card>
    </div>
  );

  const renderDoughnutCard = (data) => (
    <Box sx={{ width: 350 }}>
      <DounutChart data={data} />
    </Box>
  );
  const renderDoughnutPriceCard = () => (
    <Box sx={{ width: 350 }}>
      <DounutPriceChart data={maintenanceOrder?.analyzed} />
    </Box>
  );

  const renderBarChart = () => (
    <Box>
      <BarChartOrder data={maintenanceOrder?.analyzedOrder} />
    </Box>
  );

  const renderSummary = () => (
    <div className=" flex mx-auto my-auto ">
      <div className="lg:flex  mx-auto my-auto ">
        <div className="p-1 my-auto  ">
          <Card sx={{ width: 350, height: 120 }}>
            <div className="p-4">
              <div>ราคาการซ่อมรวม</div>
              <div className="text-3xl">
                <i class="fa-solid fa-baht-sign text-green-500 "></i>{' '}
                {currencyFormatter.format(
                  _.sumBy(
                    maintenanceOrder?.analyzed,
                    (row) => row?.total_price,
                  ),
                ) || 0}
                <span className="text-base ml-2">บาท</span>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <div className="p-4 ">{renderDoughnutPriceCard()}</div>
        </div>
      </div>
    </div>
  );

  const renderYearCardContent = (data) => (
    <div>
      <Card sx={{ bgcolor: '#FAFAFA' }}>
        <CardContent>
          <div className="text-xl font-bold ">
            {data?._id ? 'ซ่อมภายใน' : 'ซ่อมอู่นอก'}
          </div>
          <div className="lg:flex  ">
            <div className=" lg:grid grid-cols-2 gap-1   my-auto ">
              <div className="p-1  ">
                <Card sx={{ width: 350, height: 120 }}>
                  <div className="p-4">
                    <div>ราคารวม</div>
                    <div className="text-3xl">
                      <i class="fa-solid fa-baht-sign text-green-500 "></i>{' '}
                      {currencyFormatter.format(data?.total_price) || 0}
                      <span className="text-base ml-2">บาท</span>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="p-1">
                <Card sx={{ width: 350, height: 120 }}>
                  <div className="p-4">
                    <div>ค่าแรงในการบำรุงรักษา / ค่าบำรุงรักษา</div>
                    <div className="text-3xl">
                      <i class="fas fa-coins text-blue-500 "></i>{' '}
                      {currencyFormatter.format(
                        data?.total_maintenance_topic,
                      ) || 0}
                      <span className="text-base ml-2">บาท</span>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="p-1">
                <Card sx={{ width: 350, height: 120 }}>
                  <div className="p-4">
                    <div>ค่าอะไหล่</div>
                    <div className="text-3xl ">
                      <i class="fa-solid fa-screwdriver-wrench text-orange-500  "></i>{' '}
                      {currencyFormatter.format(data?.total_material_price) ||
                        0}
                      <span className="text-base ml-2">บาท</span>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="p-1">
                <Card sx={{ width: 350, height: 120 }}>
                  <div className="p-4">
                    <div>รายจ่ายเพิ่มเติม</div>
                    <div className="text-3xl">
                      <i class="fas fa-sack-dollar text-yellow-500  "></i>{' '}
                      {currencyFormatter.format(
                        data?.total_outer_service_price,
                      ) || 0}
                      <span className="text-base ml-2">บาท</span>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="mx-4 ">
              <div className="p-4">{renderDoughnutCard(data)}</div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderYearCard = () => (
    <div>
      <Card sx={{ bgcolor: '#FAFAFA' }}>
        <CardContent>
          <div className="font-semibold text-lg">
            ค่าใช้จ่ายปี {dayjs(year).format('YYYY')}
          </div>
          <div>{renderSummary()}</div>
          <div>
            <div className="py-1 ">
              {renderYearCardContent(maintenanceOrder?.analyzed?.[0])}
            </div>
            <div className="">
              {renderYearCardContent(maintenanceOrder?.analyzed?.[1])}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderQuarterCard = (data) => (
    <div>
      <div className="my-2 flex flex-wrap">
        <div className="w-full lg:w-1/2 my-2">
          <div>จำนวนรายการทั้งหมด</div>
          <div className="text-3xl">
            {/* {maintenanceOrder?.total} */}
            <span className="text-base ml-2">รายการ</span>
          </div>
        </div>
        <div className="w-full lg:w-1/2 my-2">
          <div>ราคารวม</div>
          <div className="text-3xl">
            <i class="fa-solid fa-baht-sign text-gray-500 "></i>{' '}
            {currencyFormatter.format(data?.total_price) || 0}
            <span className="text-base ml-2">บาท</span>
          </div>
        </div>
        <div className="w-full lg:w-1/3 my-2">
          <div>ค่าแรงในการบำรุงรักษา / ค่าบำรุงรักษา</div>
          <div className="text-3xl">
            <i class="fas fa-coins text-gray-500 "></i>{' '}
            {currencyFormatter.format(data?.total_maintenance_topic) || 0}
            <span className="text-base ml-2">บาท</span>
          </div>
          <div className="my-2 text-indigo-800">
            คิดเป็น{' '}
            {currencyFormatter.format(
              (data?.total_maintenance_topic * 100) / data?.total_price,
            )}{' '}
            % ของทั้งหมด
          </div>
        </div>
        <div className="w-full lg:w-1/3 my-2">
          <div>ค่าอะไหล่</div>
          <div className="text-3xl ">
            <i class="fa-solid fa-screwdriver-wrench text-gray-500  "></i>{' '}
            {currencyFormatter.format(data?.total_material_price) || 0}
            <span className="text-base ml-2">บาท</span>
          </div>
          <div className="my-2 text-indigo-800">
            คิดเป็น{' '}
            {currencyFormatter.format(
              (data?.total_material_price * 100) / data?.total_price,
            )}{' '}
            % ของทั้งหมด
          </div>
        </div>
        <div className="w-full lg:w-1/3 my-2">
          <div>ค่าแรงนอก</div>
          <div className="text-3xl">
            <i class="fas fa-sack-dollar text-gray-500  "></i>{' '}
            {currencyFormatter.format(data?.total_outer_service_price) || 0}
            <span className="text-base ml-2">บาท</span>
          </div>
          <div className="my-2 text-indigo-800">
            คิดเป็น{' '}
            {currencyFormatter.format(
              (data?.total_outer_service_price * 100) / data?.total_price,
            )}{' '}
            % ของทั้งหมด
          </div>
        </div>
      </div>
    </div>
  );

  const renderList = () => (
    <div>
      {_.map(maintenanceOrder?.quarter, (each, index) => (
        <div className="py-1">
          {' '}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <div className="font-bold">
                  {'ค่าใช้จ่ายไตรมาส' +
                    ' ' +
                    dayjs(each?._id?.truncatedOrderDate).quarter()}
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{renderQuarterCard(each)}</AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
  const jobsFillter = _.countBy(maintenanceOrder?.rows, (e) => {
    return e?.status;
  });

  const renderStatus = () => (
    <div>
      <Card sx={{}}>
        <CardContent>
          <div>
            <div className="font-bold">สถานะงาน</div>
            <div className="my-2 lg:flex gap-1 ">
              <div className="lg:w-1/3">
                กำลังดำเนินการ
                <div className="text-3xl">
                  <i class="fa-solid fa-circle-play text-blue-500 "></i>{' '}
                  {jobsFillter?.APPROVE || 0}
                </div>
              </div>
              <div className="lg:w-1/3">
                สำเร็จ{' '}
                <div className="text-3xl">
                  <i class="fa-solid fa-circle-check text-green-500 "></i>{' '}
                  {jobsFillter?.SUCCESS || 0}
                </div>
              </div>
              <div className="lg:w-1/3">
                ยกเลิก{' '}
                <div className="text-3xl">
                  <i class="fa-solid fa-circle-xmark text-orange-500  "></i>{' '}
                  {jobsFillter?.CENCLE || 0}
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
  if (!maintenanceOrder.isLoading && maintenanceOrder.isCompleted) {
    return (
      <div>
        <div>
          <div className="flex justify-between">
            {renderTitle()}
            <div className="py-4 w-1/6 ">{renderYearSelect()}</div>
          </div>
          <div>{renderStatus()}</div>
          <div className="py-2">{renderYearCard()}</div>

          <div className="lg:grid grid-cols-2 gap-1"></div>
          {/* {renderList()} */}
          {renderBarChart()}
        </div>

        {/* <MaintenanceOrderGroup />
        <div>{renderStatus()}</div>
        <div className="py-2">{renderYearCard()}</div>
        <div className="lg:grid grid-cols-2 gap-1"></div>
        {renderList()} */}
        {/* {renderTestData()} */}
      </div>
    );
  } else {
    <Loading />;
  }
}
Dashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Dashboard.defaultProps = {
  title: '',
  subtitle: '',
};
