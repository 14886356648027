import React from 'react';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { dayjs } from '../../../utils/functions';
import { DatePicker, DateTimePicker } from '../../Datepicker';

const FinishMainatenanceOrderForm = ({ control, errors }) => {
  const information = useSelector((state) => state.information);
  const {
    setting: { cmms: cmmsSetting },
  } = information;
  return (
    <div className="flex flex-wrap">
      <div className="w-full ">
        <div className="w-full px-1 my-2 py-2 font-semibold text-lg">
          วันที่แจ้งซ่อม
        </div>
        <div className="w-full  my-2 flex-wrap">
          <Controller
            name={'end_date'}
            control={control}
            defaultValue={dayjs().format('YYYY-MM-DDTHH:mm')}
            render={({ field }) =>
              cmmsSetting?.attach_time ? (
                <DateTimePicker
                  label="วันที่ซ่อมเสร็จ"
                  value={field.value}
                  setValue={(value) => field.onChange(value)}
                />
              ) : (
                <DatePicker
                  label="วันที่ซ่อมเสร็จ"
                  value={field.value}
                  setValue={(value) => field.onChange(value)}
                />
              )
            }
          />
        </div>
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'modify'}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              rows={2}
              label="วิธีการแก้ไข"
              fullWidth
              helperText={errors.modify && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
};

FinishMainatenanceOrderForm.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
};

export default FinishMainatenanceOrderForm;
