import {
  MATERIAL_ALL,
  MATERIAL_GET,
  MATERIAL_DEL,
  MATERIAL_PUT,
  MATERIAL_POST,
  MATERIAL_LOADING,
  MATERIAL_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const materialCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/material`,
      {
        ...payload,
      },
    );
    dispatch({ type: MATERIAL_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const materialInsertWithClassification =
  (payload) => async (dispatch) => {
    try {
      dispatch({ type: MATERIAL_LOADING });
      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/material/with-classify`,
        {
          ...payload,
        },
      );
      dispatch({ type: MATERIAL_POST, payload: data });
    } catch (error) {
      console.error(error);
      dispatch({ type: MATERIAL_ERROR });
      throw new Error(error?.response?.data?.error?.message);
    }
  };
export const materialInsertOneWithStocklot = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/material/with-stocklot`,
      {
        ...payload,
      },
    );
    dispatch({ type: MATERIAL_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const materialAll =
  ({
    name = '',
    size = 2000,
    page = 1,
    stockStatus = 'IN_STOCK',
    place = '',
    statusCode = '',
    selectMaterialType = '',
    startDate = '',
    endDate = '',
    sortDesc = '',
    noStockLot = '',
    filterOutEmptyStock = '',
    lowerAmountOnly = '',
    upperAmountOnly = '',
  }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/material?name=${name}&size=${size}&page=${page}&status=${stockStatus}&place=${place}&statusCode=${statusCode}&selectMaterialType=${selectMaterialType}&startDate=${startDate}&endDate=${endDate}&sortDesc=${sortDesc}&noStockLot=${noStockLot}&filterOutEmptyStock=${filterOutEmptyStock}&lowerAmountOnly=${lowerAmountOnly}&upperAmountOnly=${upperAmountOnly}`,
      );
      if (status === 200) {
        dispatch({ type: MATERIAL_ALL, payload: data });
      }
    } catch (error) {
      dispatch({ type: MATERIAL_ERROR });
      throw new Error(error);
    }
  };

export const materialGet = (id, params) => async (dispatch) => {
  try {
    const { placeId = '', startDate = '', endDate = '' } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material/${id}?place=${placeId}&startDate=${startDate}&endDate=${endDate}`,
    );

    if (status === 200) {
      dispatch({ type: MATERIAL_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error);
  }
};

export const materialPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material/${id}`,
      payload,
    );
    dispatch({ type: MATERIAL_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const materialDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/material/${id}`,
    );
    dispatch({ type: MATERIAL_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error);
  }
};

export const matrialReset = () => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_LOADING });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error);
  }
};

export const materialEmpty = () => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_ALL, payload: { total: 0, rows: [] } });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_ERROR });
    throw new Error(error);
  }
};
