import React, { useState } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import _ from 'lodash';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  showMaintenanceStatus as showStatus,
  currencyFormatter,
  showMaintenanceLevel as displayMatenanceLevel,
} from '../../../utils/functions';
import { MAINTENANCE_ORDER_STATUS } from '../../../utils/constants';
import {
  MaintenanceOrderReport,
  WithdrawMaterialReport,
  MaintenanceReport,
  TypeTwoWithdrawMaterialReport,
  DicutTypeTwoWithdrawMaterialReport,
  TypeTwoMaintenanceOrder,
  TypeTwoMaintenanceOrderDicut,
} from '../../Report';
import { MaterialCard } from '../../Card';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const MaintenanceOrderTable = ({
  maintenanceOrders,
  page,
  size,
  handleOpenMemo = () => {},
  handleUpdateStatus = () => {},
  handleChangePage = () => {},
  handleChangeRowsPerPage = () => {},
  handleApprove = () => {},
  total,
  setSelectedOrder = () => {},
  showCommandButton = true,
  showSuccessDate = false,
  showCause = false,
  showModifyMethod = false,
  showAllPrice = false,
  showMaintenanceType = true,
  showMaintenanceLevel = true,
  detailLink = 'maintenance-orders/detail',
  useSparePart,
  showAllowButton,
  maintenanceTopic,
}) => {
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const cmmsSetting = information?.setting?.cmms;
  const history = useHistory();
  const [modelCancleOpen, setModalCancleOpen] = useState(false);
  const [selectedMaintenanceOrder, setSelectedMaintenanceOrder] = useState();

  const handlePrintPdf = (row) => {
    const data = { ...row, price: false };
    MaintenanceOrderReport(data, information, me);
  };

  const handlePrintMaterialWithdraw = (row) => {
    const data = { ...row, price: false };
    WithdrawMaterialReport(data, information, me);
  };

  const handlePrintReport = (row) => {
    const data = { ...row, price: false };
    MaintenanceReport(data, information, data?.process_materials, me);
  };

  const canHandleButton = (row) => {
    // SUPERUSER
    if (me?.userData?.role?.level >= 1) {
      return true;
    }

    if (
      row?.informer?._id === me?.userData?._id &&
      (row?.status === MAINTENANCE_ORDER_STATUS.APPROVE.status_code ||
        row?.status === MAINTENANCE_ORDER_STATUS.NEW.status_code ||
        row?.status === MAINTENANCE_ORDER_STATUS.PENDING.status_code)
    ) {
      return true;
    }

    return false;
  };

  const canMajorHandleButton = (row) => {
    // ADMIN
    if (me?.userData?.role?.level >= 1) {
      if (
        row?.status === MAINTENANCE_ORDER_STATUS.APPROVE.status_code ||
        row?.status === MAINTENANCE_ORDER_STATUS.NEW.status_code ||
        row?.status === MAINTENANCE_ORDER_STATUS.PENDING.status_code
      ) {
        return true;
      }
    }

    if (
      row?.informer?._id === me?.userData?._id &&
      (row?.status === MAINTENANCE_ORDER_STATUS.APPROVE.status_code ||
        row?.status === MAINTENANCE_ORDER_STATUS.NEW.status_code ||
        row?.status === MAINTENANCE_ORDER_STATUS.PENDING.status_code)
    ) {
      return true;
    }

    return false;
  };

  const handleWithdrawMaterialReportTypeTwo = (row) => {
    try {
      TypeTwoWithdrawMaterialReport(row, information, me);
    } catch (err) {
      window.alert(`ไม่สามารถพิมพ์ PDF ได้ ${err?.message}`);
    }
  };

  const handleWithdrawMaterialReportTypeTwoDicut = (row) => {
    try {
      DicutTypeTwoWithdrawMaterialReport(row, information, me);
    } catch (err) {
      window.alert(`ไม่สามารถพิมพ์ PDF ได้ ${err?.message}`);
    }
  };

  const handleMaintenanceOrderTypeTwo = (row) => {
    try {
      TypeTwoMaintenanceOrder(row, information, me, maintenanceTopic);
    } catch (err) {
      window.alert(`ไม่สามารถพิมพ์ PDF ได้ ${err?.message}`);
    }
  };

  const handleMaintenanceOrderTypeTwoDicut = (row) => {
    try {
      TypeTwoMaintenanceOrderDicut(row, information, me, maintenanceTopic);
    } catch (err) {
      window.alert(`ไม่สามารถพิมพ์ PDF ได้ ${err?.message}`);
    }
  };

  const handleDelete = async (row) => {
    if (_.isEmpty(row?.process_materials)) {
      handleUpdateStatus(row?._id, MAINTENANCE_ORDER_STATUS.CENCLE.status_code);
    } else {
      setModalCancleOpen(true);
      setSelectedMaintenanceOrder(row);
    }
  };

  const renderCancleDialog = () => (
    <Dialog
      open={modelCancleOpen}
      onClose={() => {
        setModalCancleOpen(false);
        setSelectedMaintenanceOrder();
      }}
    >
      <DialogTitle>ยกเลิกรายการแจ้งบำรุงรักษา</DialogTitle>
      <DialogContent>
        {!_.isEmpty(selectedMaintenanceOrder?.process_materials) && (
          <div className="font-sansserif py-2">
            ยังมีรายการวัสดุที่ยังไม่คืน
          </div>
        )}
        <MaterialCard
          materialArrays={selectedMaintenanceOrder?.process_materials}
          grid={false}
        />
      </DialogContent>
      <DialogActions>
        <div className="flex flex-col gap-2 mx-2 justify-center w-full mb-4">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(
                `/cmms/material-return/${selectedMaintenanceOrder?._id}`,
              );
            }}
          >
            คืนของ
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleUpdateStatus(
                selectedMaintenanceOrder?._id,
                MAINTENANCE_ORDER_STATUS.CENCLE.status_code,
              );
            }}
          >
            ยืนยันการยกเลิกโดยไม่คืนของ
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      {renderCancleDialog()}
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">เลขที่รายการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">
                    วันที่{showSuccessDate ? '' : 'แจ้งซ่อม'}
                  </div>
                </TableCell>
                {showSuccessDate && (
                  <TableCell>
                    <div className="font-bold">วันที่ซ่อมสำเร็จ</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold">ประเภทการซ่อม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">พนักงานขับรถ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รถ (หัวรถ)</div>
                </TableCell>
                {showCause && (
                  <TableCell>
                    <div className="font-bold">สาเหตุ</div>
                  </TableCell>
                )}
                {showModifyMethod && (
                  <TableCell>
                    <div className="font-bold">วิธีแก้ไข</div>
                  </TableCell>
                )}
                {showAllPrice && (
                  <TableCell>
                    <div className="font-bold">ราคารวม</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold">หัวข้อบำรุงรักษา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สถานะ</div>
                </TableCell>
                {showCommandButton && (
                  <TableCell>
                    <div className="font-bold">ดำเนินการ</div>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(maintenanceOrders) ? (
                maintenanceOrders.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>{' '}
                    <TableCell>{row?.running_number || '-'}</TableCell>
                    <TableCell>
                      <div className="flex flex-wrap items-center">
                        {dayjs(row?.start_date || row?.createdAt).format(
                          cmmsSetting?.attach_time
                            ? 'DD MMM BBBB HH:mm'
                            : 'DD MMM BBBB ',
                        )}
                      </div>
                    </TableCell>
                    {showSuccessDate && (
                      <TableCell>
                        <div className="flex flex-wrap items-center">
                          {row?.end_date
                            ? dayjs(row?.end_date).format(
                                cmmsSetting?.attach_time
                                  ? 'DD MMM BBBB HH:mm'
                                  : 'DD MMM BBBB ',
                              )
                            : '-'}
                        </div>
                      </TableCell>
                    )}
                    <TableCell>
                      <div className="w-28 flex flex-col justify-center">
                        <div className="self-center">
                          {showMaintenanceType && (
                            <div className="text-center">
                              {`${row?.maintenance_type?.name || ' - '}`}
                            </div>
                          )}
                          {showMaintenanceLevel && (
                            <div className="my-1">
                              {displayMatenanceLevel(row?.maintenance_level)}
                            </div>
                          )}
                          <div className="text-center">
                            {row?.is_self_maintenance ? 'ซ่อมบำรุงเอง' : ''}
                          </div>{' '}
                        </div>
                        <div className="flex  gap-1 flex-col align-middle justify-center my-2 ">
                          <Link to={`${detailLink}/${row?._id}`}>
                            <Button
                              size="small"
                              variant="outlined"
                              color="secondary"
                              startIcon={<i className="fas fa-file-alt" />}
                            >
                              รายละเอียด
                            </Button>
                          </Link>

                          {cmmsSetting?.available_report
                            ?.main_maintenance_order && (
                            <Button
                              size="small"
                              variant="outlined"
                              color="info"
                              onClick={() => {
                                handlePrintPdf(row);
                              }}
                              startIcon={<i className="fas fa-print" />}
                            >
                              ใบแจ้งซ่อม
                            </Button>
                          )}
                          {cmmsSetting?.available_report
                            ?.material_withdraw_report_1 &&
                            useSparePart && (
                              <Button
                                size="small"
                                variant="outlined"
                                color="info"
                                onClick={() => {
                                  handlePrintMaterialWithdraw(row);
                                }}
                                startIcon={<i className="fas fa-toolbox" />}
                              >
                                ใบเบิกวัสดุ
                              </Button>
                            )}
                          {cmmsSetting?.available_report
                            ?.main_maintenance_report && (
                            <Button
                              size="small"
                              variant="outlined"
                              color="info"
                              onClick={() => {
                                handlePrintReport(row);
                              }}
                              startIcon={
                                <i className="fas fa-clipboard-list" />
                              }
                            >
                              รายงานการซ่อม
                            </Button>
                          )}
                          {cmmsSetting?.available_report
                            ?.material_withdraw_report_2 && (
                            <Button
                              size="small"
                              variant="outlined"
                              color="info"
                              onClick={() => {
                                handleWithdrawMaterialReportTypeTwo(row);
                              }}
                              startIcon={
                                <i className="fas fa-clipboard-list" />
                              }
                            >
                              ใบขอเบิกวัตถุดิบ
                            </Button>
                          )}
                          {cmmsSetting?.available_report
                            ?.material_withdraw_report_2_dicut && (
                            <Button
                              size="small"
                              variant="outlined"
                              color="info"
                              onClick={() => {
                                handleWithdrawMaterialReportTypeTwoDicut(row);
                              }}
                              startIcon={
                                <i className="fas fa-clipboard-list" />
                              }
                            >
                              ใบขอเบิกวัตถุดิบ (เฉพาะตัวอักษร)
                            </Button>
                          )}
                          {cmmsSetting?.available_report
                            ?.maintenance_order_report_2 && (
                            <Button
                              size="small"
                              variant="outlined"
                              color="info"
                              onClick={() => {
                                handleMaintenanceOrderTypeTwo(row);
                              }}
                              startIcon={
                                <i className="fas fa-clipboard-list" />
                              }
                            >
                              ใบแจ้งซ่อม
                            </Button>
                          )}
                          {cmmsSetting?.available_report
                            ?.maintenance_order_report_2_dicut && (
                            <Button
                              size="small"
                              variant="outlined"
                              color="info"
                              onClick={() => {
                                handleMaintenanceOrderTypeTwoDicut(row);
                              }}
                              startIcon={
                                <i className="fas fa-clipboard-list" />
                              }
                            >
                              ใบแจ้งซ่อม (เฉพาะตัวอักษร)
                            </Button>
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{`${row?.driver?.firstname || ' - '} ${
                      row?.driver?.lastname || '  '
                    }`}</TableCell>
                    <TableCell>
                      {' '}
                      <div className="w-32">
                        {row?.machinery?.name && (
                          <div>
                            <div className="font-bold">เครื่องจักร</div>
                            <div>
                              <div>
                                {`${row?.machinery?.name || ' - '}`}{' '}
                                {`${row?.machinery?.licence_number || ' '}`}
                              </div>
                            </div>
                          </div>
                        )}
                        {row?.vehicle?.licence_number && (
                          <div>
                            <div className="font-bold">หัวรถ</div>
                            <div>
                              <div>
                                {`${row?.vehicle?.type?.name || ' - '}`}{' '}
                                {`${row?.vehicle?.licence_number || ' '}`}
                              </div>
                              <div>
                                เลขไมล์{' '}
                                {row?.mileage
                                  ? `${row?.mileage?.toLocaleString()} km`
                                  : ' - '}
                              </div>
                            </div>
                          </div>
                        )}
                        {row?.trailer_tail?.licence_number && (
                          <div>
                            <div className="font-bold">หางรถ</div>
                            <div>
                              {' '}
                              <div>{`${
                                row?.trailer_tail?.type?.name || ' - '
                              }`}</div>
                              <div>{`${
                                row?.trailer_tail?.licence_number || '  '
                              }`}</div>
                            </div>
                          </div>
                        )}
                        {row?.container?.licence_number && (
                          <div>
                            <div className="font-bold">คอนเทนเนอร์</div>
                            <div>
                              {' '}
                              <div>{`${
                                row?.container?.licence_number || ' -  '
                              }`}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </TableCell>
                    {showCause && (
                      <TableCell>
                        <div>{row?.cause || '-'}</div>
                      </TableCell>
                    )}
                    {showModifyMethod && (
                      <TableCell>
                        <div>{row?.modify || '-'}</div>
                      </TableCell>
                    )}
                    {showAllPrice && (
                      <TableCell>
                        {currencyFormatter.format(row?.total_price)}
                      </TableCell>
                    )}
                    <TableCell>
                      {_.map(
                        row?.maintenance_topic,
                        (eachTopic, indexTopic) => (
                          <li key={indexTopic}> {eachTopic?.topic?.name} </li>
                        ),
                      )}
                    </TableCell>
                    <TableCell>{showStatus(row?.status)}</TableCell>
                    {showCommandButton && (
                      <TableCell>
                        <div
                          className="flex flex-wrap gap-1 "
                          style={{ maxWidth: '240px' }}
                        >
                          <div
                            className={`${row?.is_self_maintenance ? '' : ''}`}
                          >
                            <Link
                              to={`/cmms/maintenance-orders/edit/${row?._id}`}
                              hidden={!canHandleButton(row)}
                            >
                              <Button
                                variant="contained"
                                color={'warning'}
                                size={'small'}
                                fullWidth
                                disabled={!canHandleButton(row)}
                              >
                                แก้ไข
                              </Button>
                            </Link>
                          </div>
                          {showAllowButton && (
                            <div>
                              <Button
                                variant="contained"
                                color={'info'}
                                fullWidth
                                size={'small'}
                                disabled={
                                  MAINTENANCE_ORDER_STATUS.APPROVE
                                    .status_code === row.status ||
                                  MAINTENANCE_ORDER_STATUS.SUCCESS
                                    .status_code === row.status ||
                                  MAINTENANCE_ORDER_STATUS.CENCLE
                                    .status_code === row.status ||
                                  me?.userData?.role?.level < 1
                                }
                                onClick={() => handleApprove(row._id)}
                              >
                                อนุมัติ
                              </Button>
                            </div>
                          )}
                          <div>
                            <Button
                              variant="contained"
                              color={'error'}
                              fullWidth
                              size={'small'}
                              disabled={!canMajorHandleButton(row)}
                              onClick={() => {
                                handleDelete(row);
                              }}
                            >
                              ยกเลิก
                            </Button>
                          </div>
                          {!cmmsSetting?.must_approve_material_request &&
                            useSparePart && (
                              <div>
                                <Link
                                  to={`/cmms/material-withdraw/${row?._id}`}
                                  hidden={!canHandleButton(row)}
                                >
                                  <Button
                                    variant="contained"
                                    size="small"
                                    fullWidth
                                    color="secondary"
                                    disabled={!canHandleButton(row)}
                                  >
                                    เบิกวัสดุ
                                  </Button>
                                </Link>
                              </div>
                            )}

                          {cmmsSetting?.must_approve_material_request &&
                            useSparePart && (
                              <div>
                                <Link
                                  to={`material-withdraw-request/${row?._id}`}
                                  disabled={!canHandleButton(row)}
                                >
                                  <Button
                                    variant="contained"
                                    size="small"
                                    fullWidth
                                    color="secondary"
                                    disabled={!canHandleButton(row)}
                                  >
                                    {' '}
                                    ขอเบิกวัสดุ
                                  </Button>
                                </Link>
                              </div>
                            )}

                          {useSparePart && (
                            <div>
                              <Link
                                to={`material-return/${row?._id}`}
                                disabled={!canHandleButton(row)}
                              >
                                <Button
                                  variant="contained"
                                  color="info"
                                  fullWidth
                                  size="small"
                                  disabled={!canHandleButton(row)}
                                >
                                  คืนวัสดุ
                                </Button>
                              </Link>
                            </div>
                          )}
                          <div>
                            <Button
                              variant="contained"
                              color={'success'}
                              fullWidth
                              size={'small'}
                              disabled={!canMajorHandleButton(row)}
                              onClick={() => {
                                handleOpenMemo();
                                setSelectedOrder(row);
                              }}
                            >
                              สำเร็จ
                            </Button>
                          </div>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
};

MaintenanceOrderTable.propTypes = {
  maintenanceOrders: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,

  handleOpenMemo: PropTypes.func,
  handleUpdateStatus: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handlePushToDetail: PropTypes.func,
  handleApprove: PropTypes.func,
  setSelectedOrder: PropTypes.func,
  showCommandButton: PropTypes.bool,
  showSuccessDate: PropTypes.bool,
  showCause: PropTypes.bool,
  showModifyMethod: PropTypes.bool,
  showAllPrice: PropTypes.bool,
  detailLink: PropTypes.string,
  showMaintenanceLevel: PropTypes.bool,
  showMaintenanceType: PropTypes.bool,
  useSparePart: PropTypes.bool,
  showAllowButton: PropTypes.bool,
  maintenanceTopic: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default MaintenanceOrderTable;
