import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import configureStore from './redux/configureStore';
import {
  Auth,
  CRM,
  CMMS,
  HRMS,
  FMS,
  IMS,
  Information,
  Account,
  TMS,
  JMS,
  WMS,
  BMS,
  BMMS,
  MECH,
} from './layouts';
import Home from './views/Home';
import PrivateRoute from './contexts/PrivateRoute';
import AuthProvider from './contexts/AuthContext';
import theme from './theme';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/styles/index.css';

const store = configureStore();
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <div className="bg-gray-100">
            <Switch>
              <Route path="/auth" component={Auth} />
              <PrivateRoute>
                <Route exact path="/" component={Home} />
                <Route path="/auth" component={Auth} />
                <Route path="/crm" component={CRM} />
                <Route path="/cmms" component={CMMS} />

                <Route path="/hrms" component={HRMS} />
                <Route path="/fms" component={FMS} />
                <Route path="/ims" component={IMS} />
                <Route path="/tms" component={TMS} />
                <Route path="/jms" component={JMS} />
                <Route path="/wms" component={WMS} />
                <Route path="/bms" component={BMS} />
                <Route path="/bmms" component={BMMS} />
                <Route path="/mech" component={MECH} />
                <Route path="/information" component={Information} />
                <Route path="/account" component={Account} />
              </PrivateRoute>
              <Redirect from="*" to="/auth" />
            </Switch>
          </div>
        </ThemeProvider>
      </AuthProvider>
    </Provider>
  </Router>,
  document.getElementById('root'),
);
