import React, { useEffect, useState, Co } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Truck as TruckIcon,
  Grid as GridIcon,
  Users as UsersIcon,
  Sliders as SlidersIcon,
  UserPlus,
  BookOpen,
  File as FileIcon,
  DollarSign,
} from 'react-feather';
import dayjs from 'dayjs';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import accessRight from '../utils/functions/accessRight';
import Showtime from './Component/Showtime';
export default function Home() {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);

  console.log('information', information);

  useEffect(() => {
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    return () => {};
  }, []);

  if (!me.userData) {
    return <Loading />;
  }
  return (
    <div className="min-h-screen">
      <HomeNavbar onMobileNavOpen={() => {}} />
      <div className="min-h-screen ">
        <div className="md:flex py-40 lg:p-40 container pt-30 mr-auto ml-auto w-full ">
          <div className=" px-8 border-r-4 border-slate-300  md:flex hidden md:bolck    ">
            <div>
              <img className="h-40 w-50 p-4" src={information?.image_logo} />
              <br />
              <div className="flex justify-center font-sans semibol  text-xl ">
                {information?.name}
                <br />
                {dayjs().format('DD MMMM BBBB')}
              </div>
              <div className=" ">
                <Showtime />
                <div className="flex justify-center  pt-4">
                  <h2> Sponser by </h2>
                  {information?.sponsor?.name}
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3   ">
            {/* {accessRight(me, 'DOC') && (
              <div className="my-4  px-4">
                <Link to={'/doc'}>
                  <div className="bg-green-800  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <FileTextIcon size={50} color="white" />
                    </div>

                    <p className=" text-white mt-4">จัดการเอกสาร</p>
                  </div>
                </Link>
              </div>
            )} */}
            {accessRight(me, 'FMS') && (
              <div className="my-4  px-4">
                <Link to={'/fms'}>
                  <div className="bg-blue-600  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <TruckIcon size={50} color="white" />
                    </div>
                    <p className=" text-white mt-4">จัดการยานพาหนะ</p>
                  </div>
                </Link>
              </div>
            )}{' '}
            {accessRight(me, 'IMS') && (
              <div className="my-4  px-4">
                <Link to={'/ims'}>
                  <div className="bg-indigo-600  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <i className="fa-solid fa-toolbox text-white text-4xl my-2"></i>
                    </div>

                    <p className=" text-white mt-4">คลังวัสดุ</p>
                  </div>
                </Link>
              </div>
            )}
            {accessRight(me, 'WMS') && (
              <div className="my-4  px-4">
                <Link to={'/wms'}>
                  <div className="bg-violet-600  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <i className="fa-solid fa-truck-monster text-white text-4xl my-2"></i>{' '}
                    </div>
                    <p className=" text-white mt-4">จัดการยาง</p>
                  </div>
                </Link>
              </div>
            )}
            {accessRight(me, 'CMMS') && (
              <div className="my-4  px-4">
                <Link to={'/cmms'}>
                  <div className="bg-green-600  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <i className="fa-solid fa-hammer text-white text-4xl my-2"></i>
                    </div>

                    <p className=" text-white mt-4">จัดการการแจ้งซ่อมบำรุง</p>
                  </div>
                </Link>
              </div>
            )}
            {accessRight(me, 'JMS') && (
              <div className="my-4  px-4">
                <Link to={'/jms'}>
                  <div className="bg-orange-400  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <i className="fas fa-folder-tree text-white text-4xl my-2"></i>
                    </div>

                    <p className=" text-white mt-4">จัดการงานสำหรับการขนส่ง</p>
                  </div>
                </Link>
              </div>
            )}
            {accessRight(me, 'BMS') && (
              <div className="my-4  px-4">
                <Link to={'/bms'}>
                  <div className="bg-orange-500  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <i className="fas fa-file text-white text-4xl my-2"></i>
                    </div>

                    <p className=" text-white mt-4">จัดการบุ๊คกิ้ง</p>
                  </div>
                </Link>
              </div>
            )}
            {accessRight(me, 'BMMS') && (
              <div className="my-4  px-4">
                <Link to={'/bms'}>
                  <div className="bg-green-400  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <i className="fas fa-dollar-sign text-white text-4xl my-2"></i>
                    </div>

                    <p className=" text-white mt-4">จัดการบิล</p>
                  </div>
                </Link>
              </div>
            )}
            {accessRight(me, 'TMS') && (
              <div className="my-4  px-4">
                <Link to={'/tms'}>
                  <div className="bg-yellow-500  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <i className="fa-solid fa-truck-fast text-white text-4xl my-2"></i>
                    </div>

                    <p className=" text-white mt-4">จัดการขนส่ง</p>
                  </div>
                </Link>
              </div>
            )}
            {accessRight(me, 'CRM') && (
              <div className="my-4  px-4">
                <Link to={'/crm'}>
                  <div className="bg-pink-500  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <UsersIcon size={50} color="white" />
                    </div>

                    <p className=" text-white mt-4">ลูกค้าสัมพันธ์</p>
                  </div>
                </Link>
              </div>
            )}
            {accessRight(me, 'HRMS') && (
              <div className="my-4  px-4">
                <Link to={'/hrms'}>
                  <div className="bg-red-500  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      <UserPlus size={50} color="white" />
                    </div>

                    <p className=" text-white mt-4">ทรัพยากรบุคคล</p>
                  </div>
                </Link>
              </div>
            )}
            {accessRight(me, 'INFORMATION') && (
              <div className="my-4  px-4">
                <Link to={'/information'}>
                  <div className="bg-gray-500 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                    <div className="flex justify-center">
                      {/* {actions.informationAll?.information?.image_logo} */}
                      <SlidersIcon size={50} color="white" />
                    </div>

                    <p className=" text-white mt-4">ข้อมูลระบบ</p>
                  </div>
                </Link>
              </div>
            )}
            <div className="my-4  px-4">
              <a
                href="https://drive.google.com/drive/folders/1qZ5ZY6cGLArX1B8SySRNUAtSWJRftKfe?usp=sharing"
                target="_blank"
              >
                <div className="bg-cyan-500 shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                  <div className="flex justify-center">
                    <BookOpen size={50} color="white" />
                  </div>

                  <p className=" text-white mt-4">คู่มือการใช้งาน</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <MainFooter />
      </div>
    </div>
  );
}
