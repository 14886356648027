import { useState } from 'react';

export default function Showtime() {
  let time = new Date().toLocaleTimeString();
  const [currentTime, setCurrentTime] = useState(time);
  const updateTime = () => {
    let time = new Date().toLocaleTimeString();
    setCurrentTime(time);
  };
  setInterval(updateTime, 1000);

  return (
    <div className="flex justify-center rounded-lg  text-xl border-solid border-8 border-slate-600 ... p-6 m-12">
      {currentTime}
    </div>
  );
}
