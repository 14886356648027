import _ from 'lodash';

const columns = [
  {
    label: 'ลำดับที่',
    value: (col) => col?.index,
  },
  {
    label: 'ชื่อวัสดุ',
    value: (col) => col?.name || '-',
  },

  {
    label: 'คงเหลือ',
    value: (col) => `${col?.total}  ${col?.unit}` || '-',
  },

  {
    label: 'ราคา/หน่วย',
    value: (col) => col?.value_per_unit || '-',
  },
  {
    label: 'ราคารวม',
    value: (col) => col?.total_value || '-',
  },
];

const query = ({ id }) => ({
  place: id,
  fetchStockLot: true,
  fetchProcess: false,
  page: 1,
});

export default { columns, query };
