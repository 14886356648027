import dayjs from 'dayjs';
import _ from 'lodash';

const columns = [
  {
    label: 'ลำดับที่',
    value: (col) => col?.index,
  },
  {
    label: 'รหัสอ้างอิงระบบ',
    value: (col) => col?.running_number || '-',
  },
  {
    label: 'วัสดุ',
    value: (col) => col?.material.name || '-',
  },
  {
    label: 'คู่ค้า',
    value: (col) => col?.supplier?.name || '-',
  },
  {
    label: 'วันที่รับเข้าคลัง',
    value: (col) =>
      `คลัง ${col?.place?.name}\n วันที่ ${dayjs(col?.receipt_date).format(
        'DD/MM/BB',
      )}`,
  },
  {
    label: 'สถานะ',
    value: (col) => col?.status || '-',
  },
  {
    label: 'จำนวน',
    value: (col) => col?.quantity || '0',
  },
  {
    label: 'คงเหลือ',
    value: (col) => col?.amount || '0',
  },
];

const query = ({ id }) => ({
  place: id,
  fetchStockLot: true,
  fetchProcess: false,
  page: 1,
});

export default { columns, query };
