import dayjs from 'dayjs';
import _ from 'lodash';
import appConfig from '../../configs/app';

const columns = [
  {
    label: 'วันที่',
    value: (col) => col?.index,
  },
  {
    label: 'วันที่ดำเนินการ',
    value: (col) => `${dayjs(col?.createdAt).format('DD/MM/BB')}`,
  },
  {
    label: 'ประเภท',
    value: (col) => col?.material_transaction_type.name || '-',
  },
  {
    label: 'เลขอ้างอิง',
    value: (col) =>
      `${_.map(
        col?.material_stock_lot || [],
        (each) => `${each?.lotId?.running_number}`,
      )}`,
  },
  {
    label: 'วัสดุ',
    value: (col) =>
      `${_.map(
        col?.material_stock_lot || [],
        (each) => `${each?.lotId?.material?.name}`,
      )}`,
  },
  {
    label: 'ชื่อคลัง',
    value: (col) =>
      `${_.map(
        col?.material_stock_lot || [],
        (each) => `${each?.lotId?.place?.name}`,
      )}`,
  },
  {
    label: 'จำนวน',
    value: (col) =>
      `${_.map(
        col?.material_stock_lot || [],
        (each) => `${each?.quantity} ${each?.lotId?.material?.unit} `,
      )}`,
  },
  {
    label: 'คงเหลือ',
    value: (col) =>
      `${_.map(
        col?.material_stock_lot || [],
        (each) => `${each?.amount} ${each?.lotId?.material?.unit} `,
      )}`,
  },
  {
    label: 'ผู้ดำเนินการ',
    value: (col) =>
      `${col?.employee?.firstname || ''}  ${col?.employee?.lastname || ''}  `,
  },

  //   {
  //     label: 'ผู้ดำเนินการ',
  //     value: (col) => `${dayjs(col?.end_date).format('DD/MM/BB')}`,
  //   },
  {
    label: 'หมายเหตุ',
    value: (col) => col?.total_price || '-',
  },
];

const query = ({ id }) => ({
  place: id,
  fetchStockLot: true,
  fetchProcess: false,
  page: 1,
});

export default { columns, query };
