import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import _ from 'lodash';

export default function BarChartOrder({ data }) {
  console.log('data', data);
  return (
    <div>
      <Bar
        data={{
          labels: _.map(data, (row) => row?.maintenance_topic?.name),
          datasets: [
            {
              label: '',
              data: data?.map((row) => row?.count),
              backgroundColor: ['rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
              hoverOffset: 4,
            },
            {
              type: 'line',
              label: '',
              data: _.map(data, (row) => row?.count),
              fill: false,
              borderColor: 'rgb(255,99,71)',
            },
          ],
        }}
      />
    </div>
  );
}
BarChartOrder.propTypes = {
  data: PropTypes.object,
};

BarChartOrder.defaultProps = {
  data: {},
};
