import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Vehicles,
  EditVehicle,
  VehicleTypes,
  EditVehicleType,
  TrailerTail,
  EditTrailerTail,
  TrailerTailType,
  EditTrailerTailType,
  Container,
  EditContainer,
  DetailVehicle,
} from '../views/FMS';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import { NotFound } from '../components/Error';
import accessRight from '../utils/functions/accessRight';

export function FMS() {
  const module = 'FMS';
  const prefix = '/fms';
  const name = 'จัดการข้อมูลรถ';

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const { pathname } = useLocation();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : FMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());

    return () => {};
  }, []);

  if (!me.userData) {
    return <Loading />;
  }
  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="relative lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/vehicle`} />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/vehicle`}>
                <Vehicles title={'รายการรถ'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/vehicle/detail/:id`}>
                <DetailVehicle title={'ข้อมูลรถ'} subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/vehicle/edit/:id`}>
                <EditVehicle title={'แก้ไขข้อมูลรถ'} subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/trailer-tail`}>
                <TrailerTail title={'รายการหางรถ'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/trailer-tail/edit/:id`}>
                <EditTrailerTail title={'แก้ไขข้อมูลหางรถ'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/container`}>
                <Container title={'รายการตู้คอนเทนเนอร์'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/container/edit/:id`}>
                <EditContainer
                  title={'แก้ไขข้อมูลตู้คอนเทนเนอร์'}
                  subtitle={name}
                />
              </Route>
              {accessRight(me, module, 1) && (
                <Switch>
                  <Route exact path={`${prefix}/vehicle-types`}>
                    <VehicleTypes title={'จัดการประเภทรถ'} subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/vehicle-types/edit/:id`}>
                    <EditVehicleType title={'แก้ไขประเภทรถ'} subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/trailer-tail-types`}>
                    <TrailerTailType
                      title={'จัดการประเภทหางรถ'}
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/trailer-tail-types/edit/:id`}>
                    <EditTrailerTailType
                      title={'แก้ไขประเภทหางรถ'}
                      subtitle={name}
                    />
                  </Route>
                </Switch>
              )}
              <Route exact path={`${prefix}/vehicle-types`}>
                <VehicleTypes title={'จัดการประเภทรถ'} subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/vehicle-types/edit/:id`}>
                <EditVehicleType title={'แก้ไขประเภทรถ'} subtitle={name} />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return <></>;
}

export default FMS;
