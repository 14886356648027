/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, TablePagination } from '@mui/material';
import _ from 'lodash';
import dayjs from 'dayjs';
export default function RefuelHistoryTable({
  refuelTransition,
  handleChangePage,
  handleChangeRowsPerPage,
  size,
  page,
  total,
}) {
  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {' '}
                  <h1 className="font-bold">ครั้งที่ </h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">วันที่</h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">เลขไมล์</h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">ราคา</h1>
                </TableCell>
                <TableCell align="center">
                  <h1 className="font-bold">ลิตร</h1>
                </TableCell>
                {/* <TableCell align="center">รายละเอียด</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {console.log('Refuel', refuelTransition)}
              {_.map(refuelTransition, (eachData, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {dayjs(eachData?.createdAt).format(
                      'DD-MM-BBBB เวลา  HH:mm',
                    )}
                  </TableCell>
                  <TableCell align="center">{eachData?.mileage}</TableCell>
                  <TableCell align="center">{eachData?.refuel?.cost}</TableCell>
                  <TableCell align="center">{index + 1}</TableCell>
                  {/* <TableCell align="center"></TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
}
