import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Avatar,
} from '@mui/material';
import _ from 'lodash';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  showMaintenanceStatus as showStatus,
  showMaintenanceLevel as displayMatenanceLevel,
} from '../../../utils/functions';
import { MAINTENANCE_ORDER_STATUS } from '../../../utils/constants';
import {
  MaintenanceOrderReport,
  WithdrawMaterialReport,
  MaintenanceReport,
  TypeTwoWithdrawMaterialReport,
  TypeTwoMaintenanceOrder,
} from '../../Report';
import MaterialCard from '../MMS/MaterialCard';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const MaintenanceOrderListCard = ({
  maintenanceOrders,
  page,
  size,
  handleOpenMemo = () => {},
  handleUpdateStatus = () => {},
  setSize,
  handleApprove = () => {},
  total,
  setSelectedOrder = () => {},
  showCommandButton = true,
  showSuccessDate = false,
  showCause = false,
  showModifyMethod = false,
  showMaintenanceLevel = true,
  useSparePart,
  showAllowButton,
  maintenanceTopic,
}) => {
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const cmmsSetting = information?.setting?.cmms;
  const history = useHistory();
  const [modelCancleOpen, setModalCancleOpen] = useState(false);
  const [selectedMaintenanceOrder, setSelectedMaintenanceOrder] = useState();

  const handlePrintPdf = (row) => {
    const data = { ...row, price: false };
    MaintenanceOrderReport(data, information, me);
  };

  const handlePrintMaterialWithdraw = (row) => {
    const data = { ...row, price: false };
    WithdrawMaterialReport(data, information, me);
  };

  const handlePrintReport = (row) => {
    const data = { ...row, price: false };
    MaintenanceReport(data, information, data?.process_materials, me);
  };

  const canHandleButton = (row) => {
    // SUPERUSER
    if (me?.userData?.role?.level >= 1) {
      return true;
    }

    if (
      row?.informer?._id === me?.userData?._id &&
      (row?.status === MAINTENANCE_ORDER_STATUS.APPROVE.status_code ||
        row?.status === MAINTENANCE_ORDER_STATUS.NEW.status_code ||
        row?.status === MAINTENANCE_ORDER_STATUS.PENDING.status_code)
    ) {
      return true;
    }

    return false;
  };

  const canMajorHandleButton = (row) => {
    // ADMIN
    if (me?.userData?.role?.level >= 1) {
      if (
        row?.status === MAINTENANCE_ORDER_STATUS.APPROVE.status_code ||
        row?.status === MAINTENANCE_ORDER_STATUS.NEW.status_code ||
        row?.status === MAINTENANCE_ORDER_STATUS.PENDING.status_code
      ) {
        return true;
      }
    }

    if (
      row?.informer?._id === me?.userData?._id &&
      (row?.status === MAINTENANCE_ORDER_STATUS.APPROVE.status_code ||
        row?.status === MAINTENANCE_ORDER_STATUS.NEW.status_code ||
        row?.status === MAINTENANCE_ORDER_STATUS.PENDING.status_code)
    ) {
      return true;
    }

    return false;
  };

  const handleWithdrawMaterialReportTypeTwo = (row) => {
    try {
      TypeTwoWithdrawMaterialReport(row, information, me);
    } catch (err) {
      window.alert(`ไม่สามารถพิมพ์ PDF ได้ ${err?.message}`);
    }
  };

  const handleMaintenanceOrderTypeTwo = (row) => {
    try {
      TypeTwoMaintenanceOrder(row, information, me, maintenanceTopic);
    } catch (err) {
      window.alert(`ไม่สามารถพิมพ์ PDF ได้ ${err?.message}`);
    }
  };

  const handleDelete = async (row) => {
    if (_.isEmpty(row?.process_materials)) {
      handleUpdateStatus(row?._id, MAINTENANCE_ORDER_STATUS.CENCLE.status_code);
    } else {
      setModalCancleOpen(true);
      setSelectedMaintenanceOrder(row);
    }
  };

  const renderCancleDialog = () => (
    <Dialog
      open={modelCancleOpen}
      onClose={() => {
        setModalCancleOpen(false);
        setSelectedMaintenanceOrder();
      }}
    >
      <DialogTitle>ยกเลิกรายการแจ้งบำรุงรักษา</DialogTitle>
      <DialogContent>
        {!_.isEmpty(selectedMaintenanceOrder?.process_materials) && (
          <div className="font-sansserif py-2">
            ยังมีรายการวัสดุที่ยังไม่คืน
          </div>
        )}
        <MaterialCard
          materialArrays={selectedMaintenanceOrder?.process_materials}
          grid={false}
        />
      </DialogContent>
      <DialogActions>
        <div className="flex flex-col gap-2 mx-2 justify-center w-full mb-4">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(
                `/cmms/material-return/${selectedMaintenanceOrder?._id}`,
              );
            }}
          >
            คืนของ
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleUpdateStatus(
                selectedMaintenanceOrder?._id,
                MAINTENANCE_ORDER_STATUS.CENCLE.status_code,
              );
            }}
          >
            ยืนยันการยกเลิกโดยไม่คืนของ
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      {renderCancleDialog()}
      <div className="grid lg:grid-cols-3 gap-1">
        {maintenanceOrders?.map((row) => (
          <Card key={row._id} className="my-2">
            <CardContent>
              <div className="flex justify-between">
                <Link
                  to={`/cmms/maintenance-orders/detail/${row?._id}`}
                  className="hover:font-semibold hover:underline"
                >
                  หมายเลขรายการ {row?.running_number || '-'}
                </Link>
                <Link
                  to={`/cmms/maintenance-orders/edit/${row?._id}`}
                  hidden={!canHandleButton(row)}
                >
                  <Button
                    color="inherit"
                    size={'small'}
                    // variant="outlined"
                    // fullWidth
                    disabled={!canHandleButton(row)}
                    startIcon={<i className="fas fa-pencil-alt text-sm"></i>}
                  >
                    แก้ไข
                  </Button>
                </Link>
              </div>
              <div className="flex">
                <div className="font-sansserif text-gray-400 text-sm">
                  วันที่แจ้ง :{' '}
                  {dayjs(row?.start_date || row?.createdAt).format(
                    cmmsSetting?.attach_time
                      ? 'DD MMM BBBB HH:mm'
                      : 'DD MMM BBBB ',
                  )}
                </div>
                {showSuccessDate && (
                  <div className="font-sansserif text-gray-400 text-sm">
                    วันที่สำเร็จ :{' '}
                    {row?.end_date
                      ? dayjs(row?.end_date).format(
                          cmmsSetting?.attach_time
                            ? 'DD MMM BBBB HH:mm'
                            : 'DD MMM BBBB ',
                        )
                      : '-'}
                  </div>
                )}
              </div>{' '}
              <div className="flex gap-2 my-2">
                {<div className="text-sm">{showStatus(row?.status)}</div>}{' '}
                {showMaintenanceLevel && (
                  <div className="text-sm">
                    {displayMatenanceLevel(row?.maintenance_level)}
                  </div>
                )}
              </div>
              <div className="font-sansserif my-2">
                <div className="flex">
                  <div className="w-1/2">
                    <div className="font-bold text-sm">หัวรถ</div>
                    <div>
                      <div>
                        {`${row?.vehicle?.type?.name || ' - '}`}{' '}
                        {`${row?.vehicle?.licence_number || ' '}`}
                      </div>
                      <div className="text-sm text-gray-700">
                        เลขไมล์{' '}
                        {row?.mileage
                          ? `${row?.mileage?.toLocaleString()} km`
                          : ' - '}
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="font-bold text-sm">หางรถ</div>
                    <div>
                      <div>{`${row?.trailer_tail?.type?.name || ' - '}`}</div>
                      <div>{`${
                        row?.trailer_tail?.licence_number || '  '
                      }`}</div>
                    </div>
                  </div>
                </div>
                <div className="flex my-1">
                  <div className="w-1/2">
                    <div className="font-bold text-sm">คอนเทนเนอร์</div>
                    <div>
                      <div>{`${row?.container?.licence_number || ' -  '}`}</div>
                    </div>
                  </div>
                  <div className="w-1/2"></div>
                </div>
              </div>
              <div className="my-2">
                <div className="font-bold text-sm font-sansserif">
                  พนักงานขับรถ
                </div>
                <div className="flex gap-1 my-1">
                  <Avatar
                    src={row?.driver?.image?.url}
                    size="small"
                    alt={row?.driver?.firstname || ''}
                  >
                    {_.upperCase(row?.driver?.firstname?.[0] || '')}
                    {_.upperCase(row?.driver?.lastname?.[0] || '')}
                  </Avatar>
                  <div className="self-center">
                    {row?.driver?.firstname || '-'}{' '}
                    {row?.driver?.lastname || '  '}
                  </div>
                </div>
              </div>
              <div className="font-sansserif text-sm">
                <div className="font-semibold">รายการซ่อม</div>
                <div className="ml-1 my-1">
                  {_.map(row?.maintenance_topic, (eachTopic, indexTopic) => (
                    <li key={indexTopic}> {eachTopic?.topic?.name} </li>
                  ))}
                </div>
              </div>
              {showCause && (
                <div className="font-sansserif text-sm">
                  <div className="font-semibold">สาเหตุ</div>
                  <div> {row?.cause || '-'}</div>
                </div>
              )}
              {showModifyMethod && (
                <div className="font-sansserif text-sm">
                  <div className="font-semibold">วิธีการแก้ไข</div>
                  <div> {row?.modify || '-'}</div>
                </div>
              )}
              <div className="flex overflow-x-auto  gap-1 align-middle justify-center my-2 mt-4 ">
                {cmmsSetting?.available_report?.main_maintenance_order && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    onClick={() => {
                      handlePrintPdf(row);
                    }}
                    startIcon={<i className="fas fa-print" />}
                  >
                    ใบแจ้ง
                  </Button>
                )}
                {cmmsSetting?.available_report?.material_withdraw_report_1 &&
                  useSparePart && (
                    <Button
                      size="small"
                      variant="outlined"
                      color="info"
                      onClick={() => {
                        handlePrintMaterialWithdraw(row);
                      }}
                      startIcon={<i className="fas fa-toolbox" />}
                    >
                      ใบเบิก
                    </Button>
                  )}
                {cmmsSetting?.available_report?.main_maintenance_report && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    onClick={() => {
                      handlePrintReport(row);
                    }}
                    startIcon={<i className="fas fa-clipboard-list" />}
                  >
                    รายงาน
                  </Button>
                )}
                {cmmsSetting?.available_report?.material_withdraw_report_2 && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    onClick={() => {
                      handleWithdrawMaterialReportTypeTwo(row);
                    }}
                    startIcon={<i className="fas fa-clipboard-list" />}
                  >
                    ใบเบิก
                  </Button>
                )}

                {cmmsSetting?.available_report?.maintenance_order_report_2 && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    onClick={() => {
                      handleMaintenanceOrderTypeTwo(row);
                    }}
                    startIcon={<i className="fas fa-clipboard-list" />}
                  >
                    ใบแจ้ง
                  </Button>
                )}
              </div>
              {showCommandButton && (
                <div className="flex gap-1 justify-center">
                  {showAllowButton && (
                    <div>
                      <Button
                        variant="contained"
                        color={'info'}
                        fullWidth
                        size={'small'}
                        disabled={
                          MAINTENANCE_ORDER_STATUS.APPROVE.status_code ===
                            row.status ||
                          MAINTENANCE_ORDER_STATUS.SUCCESS.status_code ===
                            row.status ||
                          MAINTENANCE_ORDER_STATUS.CENCLE.status_code ===
                            row.status ||
                          me?.userData?.role?.level < 1
                        }
                        onClick={() => handleApprove(row._id)}
                      >
                        อนุมัติ
                      </Button>
                    </div>
                  )}
                  <div>
                    <Button
                      variant="contained"
                      color={'error'}
                      fullWidth
                      size={'small'}
                      disabled={!canMajorHandleButton(row)}
                      onClick={() => {
                        handleDelete(row);
                      }}
                    >
                      ยกเลิก
                    </Button>
                  </div>
                  {!cmmsSetting?.must_approve_material_request && useSparePart && (
                    <div>
                      <Link
                        to={`/cmms/material-withdraw/${row?._id}`}
                        hidden={!canHandleButton(row)}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          fullWidth
                          color="secondary"
                          disabled={!canHandleButton(row)}
                        >
                          เบิกวัสดุ
                        </Button>
                      </Link>
                    </div>
                  )}

                  {cmmsSetting?.must_approve_material_request && useSparePart && (
                    <div>
                      <Link
                        to={`material-withdraw-request/${row?._id}`}
                        disabled={!canHandleButton(row)}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          fullWidth
                          color="secondary"
                          disabled={!canHandleButton(row)}
                        >
                          {' '}
                          ขอเบิกวัสดุ
                        </Button>
                      </Link>
                    </div>
                  )}

                  {useSparePart && (
                    <div>
                      <Link
                        to={`material-return/${row?._id}`}
                        disabled={!canHandleButton(row)}
                      >
                        <Button
                          variant="contained"
                          color="info"
                          fullWidth
                          size="small"
                          disabled={!canHandleButton(row)}
                        >
                          คืนวัสดุ
                        </Button>
                      </Link>
                    </div>
                  )}
                  <div>
                    <Button
                      variant="contained"
                      color={'success'}
                      fullWidth
                      size={'small'}
                      disabled={!canMajorHandleButton(row)}
                      onClick={() => {
                        handleOpenMemo();
                        setSelectedOrder(row);
                      }}
                    >
                      สำเร็จ
                    </Button>
                  </div>
                </div>
              )}
            </CardContent>
          </Card>
        ))}
      </div>{' '}
      <div className="flex justify-center">
        {page * size < total ? (
          <Button
            variant="contained"
            onClick={() => {
              setSize(size + 5);
            }}
          >
            {' '}
            เพิ่มอีก 5 รายการ
          </Button>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

MaintenanceOrderListCard.propTypes = {
  maintenanceOrders: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,

  handleOpenMemo: PropTypes.func,
  handleUpdateStatus: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handlePushToDetail: PropTypes.func,
  handleApprove: PropTypes.func,
  setSelectedOrder: PropTypes.func,
  showCommandButton: PropTypes.bool,
  showSuccessDate: PropTypes.bool,
  showCause: PropTypes.bool,
  showModifyMethod: PropTypes.bool,
  showAllPrice: PropTypes.bool,
  showMaintenanceLevel: PropTypes.bool,
  useSparePart: PropTypes.bool,
  showAllowButton: PropTypes.bool,
  setSize: PropTypes.func,
  maintenanceTopic: PropTypes.object,
};

export default MaintenanceOrderListCard;
