import dayjs from 'dayjs';
import _ from 'lodash';
import appConfig from '../../configs/app';

const columns = [
  {
    label: 'วันที่',
    value: (col) => `${dayjs(col?.start_date).format('DD/MM/BB')}`,
  },
  {
    label: 'หัวรถ(ป้ายทะเบียน)',
    value: (col) => col?.vehicle?.licence_number || '-',
  },
  {
    label: 'หางรถ(ป้ายทะเบียน)',
    value: (col) => col?.trailer_tail.licence_number || '-',
  },
  {
    label: 'เครื่องจักร',
    value: (col) => col?.machinery?.licence_number || '-',
  },
  {
    label: 'หัวข้อการซ่อมบำรุง',
    value: (col) =>
      `${_.map(
        col?.maintenance_topic || [],
        (each) => `${each?.topic?.name} \n`,
      )}`,
  },
  {
    label: 'สาเหตุ',
    value: (col) => col?.cause || '-',
  },
  {
    label: 'วัสดุที่เบิกมา',
    value: (col) =>
      `${_.map(
        col?.process_materials || [],
        (each) =>
          `${each?.material?.name} ${each?.amount} ${each?.material?.unit} \n`,
      )}`,
  },
  {
    label: 'วิธีซ่อมบำรุง',
    value: (col) => col?.modify || '-',
  },

  {
    label: 'แล้วเสร็จ',
    value: (col) => `${dayjs(col?.end_date).format('DD/MM/BB')}`,
  },
  {
    label: 'ราคาซ่อมรวม',
    value: (col) => col?.total_price || '-',
  },
  {
    label: 'ผู้ปฏิบัติ/ผู้แจ้ง',
    value: (col) =>
      `ผู้แจ้ง ${col?.informer.firstname || ''}  ${
        col?.informer.lastname || ''
      }  ผู้ปฏิบัติ  ${col?.workrecip || ''}  `,
  },

  {
    label: 'หมายเหตุ',
    value: '-',
  },
];

const query = ({ id }) => ({
  place: id,
  fetchStockLot: true,
  fetchProcess: false,
  page: 1,
});

export default { columns, query };
