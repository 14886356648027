/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable import/order */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { dayjs } from '../../../utils/functions';
import { DatePicker } from '../../Datepicker';
import UploadFile from '../CRM/UploadFile';

export function VehicleForm({
  errors,
  vehicle,
  vehicletype,
  Controller,
  control,
  watch,
  setValue,
  FileOpen,
  file,
  setFile,
  setFileOpen,
}) {
  const uploadfile = () => (
    <form>
      <UploadFile
        isOpen={FileOpen}
        files={file}
        setFiles={setFile}
        handleClose={() => setFileOpen(false)}
      />
    </form>
  );
  return (
    <div className="flex flex-row flex-wrap ">
      <div className="w-full px-1 py-2">
        <Controller
          name={'licence_number'}
          control={control}
          defaultValue={vehicle ? vehicle.licence_number : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ป้ายทะเบียน"
              required
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors.licence_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'type'}
          control={control}
          defaultValue={vehicle ? vehicle?.type?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                ประเภทรถ
              </InputLabel>
              <Select {...field} label="ประเภทรถ" size={'small'} fullWidth>
                {_.size(vehicletype?.rows) ? (
                  _.map(vehicletype.rows, (row) => (
                    <MenuItem key={row._id} value={row._id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>{' '}
      <div className="w-full py-2 px-1 font-semibold ">เกี่ยวกับรถ</div>
      <div className="w-full md:w-1/2 px-1 py-2">
        <Controller
          name={'attribute.brand'}
          control={control}
          defaultValue={vehicle ? vehicle?.attribute?.brand : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ยี่ห้อ"
              fullWidth
              size={'small'}
              helperText={errors.attribute?.brand && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full md:w-1/2 px-1 py-2">
        <Controller
          name={'attribute.generation'}
          control={control}
          defaultValue={vehicle ? vehicle?.attribute?.generation : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รุ่น"
              fullWidth
              size={'small'}
              helperText={errors.attribute?.generation && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full md:w-1/2 px-1 py-2">
        <Controller
          name={'attribute.chassis_number'}
          control={control}
          defaultValue={vehicle ? vehicle?.attribute?.chassis_number : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขคัชชี"
              fullWidth
              size={'small'}
              helperText={errors.attribute?.chassis_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full md:w-1/2 px-1 py-2">
        <Controller
          name={'attribute.body_number'}
          control={control}
          defaultValue={vehicle ? vehicle?.attribute?.body_number : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขตัวถัง"
              fullWidth
              size={'small'}
              helperText={errors.attribute?.body_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full md:w-1/2 px-1 py-2">
        <Controller
          name={'attribute.buy_year'}
          control={control}
          defaultValue={vehicle?.buy_year}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ปีที่ซื้อ"
              fullWidth
              size={'small'}
              helperText={errors.attribute?.buy_year && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full py-2 px-1 font-semibold ">ข้อมูลประกันภัย</div>
      <div className="w-full md:w-1/2 px-1 py-2">
        <Controller
          name={'attribute.insurance_brand'}
          control={control}
          defaultValue={vehicle?.attribute?.insurance_brand}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อประกันภัย"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full md:w-1/2 px-1 py-2">
        <Controller
          name={'attribute.insurance_number'}
          control={control}
          defaultValue={vehicle?.attribute?.insurance_number}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่กรมธรรม์"
              fullWidth
              size={'small'}
              helperText={
                errors.attribute?.insurance_number && 'กรุณาใส่ข้อมูล'
              }
            />
          )}
        />
      </div>
      <div className="w-full md:w-1/2 px-1 py-2">
        {!watch('no_insuarance_start') && (
          <Controller
            name={'attribute.insurance_start'}
            control={control}
            defaultValue={
              vehicle?.attribute?.insurance_start
                ? dayjs(vehicle?.attribute?.insurance_start).format(
                    'YYYY-MM-DD',
                  )
                : null
            }
            rules={{ required: false }}
            render={({ field }) => (
              <DatePicker
                {...field}
                setValue={field.onChange}
                label="วันที่เริ่มประกันภัย"
                value={field?.value}
              />
            )}
          />
        )}
        <Controller
          name="no_insuarance_start"
          control={control}
          defaultValue={
            vehicle?.attribute ? !vehicle?.attribute?.insurance_start : false
          }
          render={({ field }) => (
            <FormControlLabel
              label="ไม่ระบุวันเริ่มประกันภัย"
              control={
                <Checkbox
                  {...field}
                  defaultChecked={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('attribute.insurance_start', null);
                  }}
                />
              }
            />
          )}
        />
      </div>
      <div className="w-full md:w-1/2 px-1 py-2">
        {!watch('no_insuarance_end') && (
          <Controller
            name={'attribute.insurance_end'}
            control={control}
            defaultValue={
              vehicle?.attribute?.insurance_end
                ? dayjs(vehicle?.attribute?.insurance_end).format('YYYY-MM-DD')
                : null
            }
            rules={{ required: false }}
            render={({ field }) => (
              <DatePicker
                {...field}
                setValue={field.onChange}
                label="วันสิ้นสุดประกันภัย"
                value={field?.value}
              />
            )}
          />
        )}
        <Controller
          name="no_insuarance_end"
          control={control}
          defaultValue={
            vehicle?.attribute ? !vehicle?.attribute?.insurance_end : false
          }
          render={({ field }) => (
            <FormControlLabel
              label="ไม่ระบุวันสิ้นสุดประกันภัย"
              control={
                <Checkbox
                  {...field}
                  defaultChecked={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('attribute.insurance_end', null);
                  }}
                />
              }
            />
          )}
        />
      </div>
      <div>
        <div className="mt-2">{uploadfile()}</div>
      </div>
      <div className="pt-2 pr-8 ">
        <Button
          variant="contained"
          // startIcon={<FileUploadIcon />}
          onClick={() => setFileOpen(true)}
        >
          อัพโหลดเอกสาร
        </Button>
        <div>
          {vehicle?.file && (
            <div className="pl-4">
              <a href={vehicle?.file?.url} target="_blank">
                <h1 className="text-blue-600 font-bold ">
                  {vehicle?.file?.file_name}
                </h1>
              </a>
            </div>
          )}
          {!_.isEmpty(file) && (
            <div className="pl-4">
              <h1 className="font-bold">ไฟล์ใหม่</h1>
              <h1>{file?.[0]?.name}</h1>
            </div>
          )}{' '}
        </div>
      </div>
      <div className="w-full py-2 px-1 font-semibold ">
        เกี่ยวกับ พรบ.รถยนต์
      </div>
      <div className="w-full md:w-1/2 px-1 py-2">
        <Controller
          name={'attribute.invoice_number'}
          control={control}
          defaultValue={vehicle?.attribute?.invoice_number || '-'}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่ พรบ."
              fullWidth
              size={'small'}
              helperText={errors.attribute?.invoice_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>{' '}
      <div className="w-full md:w-1/2 px-1 py-2">
        {!watch('no_invoice_date') && (
          <Controller
            name={'attribute.invoice_date'}
            control={control}
            defaultValue={
              vehicle?.attribute?.invoice_date
                ? dayjs(vehicle?.attribute?.invoice_date).format('YYYY-MM-DD')
                : null
            }
            rules={{ required: false }}
            render={({ field }) => (
              <DatePicker
                {...field}
                setValue={field.onChange}
                label="วันสิ้นสุด พรบ. / วันที่ต้องต่ออายุ"
                value={field?.value}
                onChange={(e) => {
                  field.onChange(e);
                  setValue('attribute.invoice_date', null);
                }}
              />
            )}
          />
        )}
        <Controller
          name="no_invoice_date"
          control={control}
          defaultValue={
            vehicle?.attribute ? !vehicle?.attribute?.invoice_date : false
          }
          render={({ field }) => (
            <FormControlLabel
              label="ไม่ระบุวันสิ้นสุด พรบ. / วันที่ต้องต่ออายุ"
              control={<Checkbox {...field} defaultChecked={field.value} />}
            />
          )}
        />
      </div>
    </div>
  );
}

VehicleForm.propTypes = {
  errors: PropTypes.shape({
    licence_number: PropTypes.object,
    type: PropTypes.object,
    attribute: PropTypes.object,
  }),
  vehicle: PropTypes.object,
  vehicletype: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
};

VehicleForm.defaultProps = {
  vehicle: null,
};

export default VehicleForm;
