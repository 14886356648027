import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  createFilterOptions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

import PropTypes from 'prop-types';

const AttachDriverForm = ({
  driverRendereing,
  watch,
  control,
  resetField,
  maintenanceOrder,
  errors,
  employee,
  handleCreateEmployee = () => {},
}) => {
  const filter = createFilterOptions();
  const [openAddDriver, setOpenAddDriver] = useState(false);
  const [driverName, setDriverName] = useState('');
  const [driverSurname, setDriverSurname] = useState('');
  const [driverNickname, setDriverNickname] = useState('');
  const [driverTel, setDriverTel] = useState('');

  const renderAddMaintenanceDialog = () => (
    <Dialog onClose={() => setOpenAddDriver(false)} open={openAddDriver}>
      <DialogTitle>สร้างพนักงาน</DialogTitle>
      <DialogContent className="h-96">
        <div className="w-full my-2"></div>
        <div className=" my-2 flex flex-row w-96">
          <TextField
            label="ชื่อพนักงาน"
            value={driverName}
            fullWidth
            size="small"
            required
            onChange={(event) => {
              setDriverName(event.target.value);
            }}
          />
        </div>
        <div className=" my-2">
          <TextField
            label="นามสกุลพนักงาน"
            value={driverSurname}
            fullWidth
            size="small"
            onChange={(event) => {
              setDriverSurname(event.target.value);
            }}
          />{' '}
        </div>
        <div className=" my-2">
          <TextField
            label="ชื่อเล่น"
            value={driverNickname}
            fullWidth
            size="small"
            onChange={(event) => {
              setDriverNickname(event.target.value);
            }}
          />{' '}
        </div>
        <div className=" my-2">
          <TextField
            label="หมายเลขโทรศัพท์"
            value={driverTel}
            fullWidth
            size="small"
            onChange={(event) => {
              setDriverTel(event.target.value);
            }}
          />
        </div>
        <DialogActions>
          <Button
            onClick={async () => {
              await handleCreateEmployee({
                firstname: driverName,
                lastname: driverSurname,
                nickname: driverNickname,
                tel: driverTel,
              });
              setOpenAddDriver(false);
              setDriverName('');
              setDriverSurname('');
              setDriverNickname('');
              setDriverTel('');
            }}
            variant="contained"
          >
            เพิ่ม
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );

  return (
    <div className="w-full px-1 py-2">
      {renderAddMaintenanceDialog()}
      {driverRendereing &&
        !watch('no_driver_specify') &&
        !_.isEmpty(employee?.rows) && (
          <Controller
            name={'driver'}
            control={control}
            defaultValue={maintenanceOrder?.driver}
            rules={{ required: false }}
            render={({ field }) => (
              <Autocomplete
                name={field.name}
                ref={field.ref}
                defaultValue={field.value}
                options={employee.rows}
                autoHighlight
                getOptionLabel={(option) => {
                  // e.g value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option?.inputValue) {
                    return option?.inputValue;
                  }
                  return `${option?.firstname || ''} ${
                    option?.lastname || ''
                  } ${option?.nickname ? `( ${option?.nickname || ''} )` : ''}`;
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      fontSize: 15,
                      '& > span': { mr: '10px', fontSize: 18 },
                    }}
                    {...props}
                  >
                    {`${option?.firstname || ''} ${option?.lastname || ''} ${
                      option?.nickname ? `( ${option?.nickname || ''} )` : ''
                    }`}
                  </Box>
                )}
                onChange={(e, value) => {
                  if (typeof value === 'string') {
                    setTimeout(() => {
                      setOpenAddDriver(true);
                      setDriverName(value);
                    });
                  } else if (value && value?.inputValue) {
                    setOpenAddDriver(true);
                    setDriverName(value?.inputValue);
                  } else {
                    field.onChange(value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="พนักงานขับรถ"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    helperText={errors.driver && 'กรุณาใส่ข้อมูล'}
                  />
                )}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (params?.inputValue !== '') {
                    filtered.push({
                      inputValue: params?.inputValue,
                      firstname: `เพิ่ม "${params?.inputValue}"  ลงในระบบ`,
                    });
                  }

                  return filtered;
                }}
              />
            )}
          />
        )}
      <div className="w-full px-1 py-2">
        <FormControlLabel
          control={
            <Controller
              name={'no_driver_specify'}
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    resetField('driver');
                  }}
                  checked={field.value}
                />
              )}
            />
          }
          label={'ไม่ระบุคนขับรถ'}
        />
      </div>
    </div>
  );
};

AttachDriverForm.propTypes = {
  driverRendereing: PropTypes.bool,
  watch: PropTypes.func,
  control: PropTypes.object,
  employee: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  resetField: PropTypes.func,
  maintenanceOrder: PropTypes.object,
  errors: PropTypes.object,
  handleCreateEmployee: PropTypes.func,
};

export default AttachDriverForm;
