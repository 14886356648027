import React from 'react';
import _ from 'lodash';
import { Doughnut } from 'react-chartjs-2';
import { Chart } from 'chart.js/auto';

Chart.defaults.plugins.legend.position = 'right';
export default function DounutChart({ data }) {
  return (
    <div>
      <Doughnut
        data={{
          labels: ['ค่าบำรุงรักษา', 'ค่าอะไหล่', 'รายจ่ายเพิ่มเติม'],
          datasets: [
            {
              label: '',
              data: [
                data?.total_maintenance_topic,
                data?.total_material_price,
                data?.total_outer_service_price,
              ],
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
              ],
            },
          ],
        }}
      />
    </div>
  );
}
