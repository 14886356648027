import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import _ from 'lodash';

export default function DounutPriceChart({ data }) {
  return (
    <div>
      <Doughnut
        data={{
          labels: data?.map((row) => (row?._id ? 'ซ่อมภายใน' : 'ซ่อมภายนอก')),
          datasets: [
            {
              label: '',
              data: data?.map((row) => row?.total_price),
              backgroundColor: ['rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
              hoverOffset: 4,
            },
          ],
        }}
      />
    </div>
  );
}
