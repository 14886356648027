import api from '../../../utils/functions/api';
import {
  MILEAGETRANSECTION_ALL,
  MILEAGETRANSECTION_GET,
  MILEAGETRANSECTION_POST,
  MILEAGETRANSECTION_PUT,
  MILEAGETRANSECTION_DELETE,
  MILEAGETRANSECTION_LOADING,
  MILEAGETRANSECTION_ERROR,
} from '../types';
export const mileageTransactionCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MILEAGETRANSECTION_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/mileage-transaction`,
      {
        ...payload,
      },
    );
    dispatch({ type: MILEAGETRANSECTION_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MILEAGETRANSECTION_ERROR });
  }
};

export const mileageTransactionAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1, vehicle = '' } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/mileage-transaction?name=${name}&size=${size}&page=${page}&vehicle=${vehicle}`,
    );
    if (status === 200) {
      dispatch({ type: MILEAGETRANSECTION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MILEAGETRANSECTION_ERROR });
  }
};
export const mileageTransactionGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/mileage-transaction/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MILEAGETRANSECTION_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MILEAGETRANSECTION_ERROR });
  }
};

export const mileageTransactionPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MILEAGETRANSECTION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/mileage-transaction/${id}`,
      payload,
    );
    dispatch({ type: MILEAGETRANSECTION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MILEAGETRANSECTION_ERROR });
  }
};
export const mileageTransactionDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MILEAGETRANSECTION_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/mileage-transaction/${id}`,
    );
    dispatch({ type: MILEAGETRANSECTION_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MILEAGETRANSECTION_ERROR });
  }
};
