/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import THBText from 'thai-baht-text';
import floatingCurrencyFormatter from '../../utils/functions/floatingCurrencyFormater';
import currencyFormater from '../../utils/functions/currencyFormat';
import { MAINTENANCE_ORDER_STATUS } from '../../utils/constants';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
  Kanit: {
    normal: 'Kanit-Light.ttf',
    bold: 'Kanit-Regular.ttf',
    italics: 'Kanit-LightItalic.ttf',
    bolditalics: 'Kanit-Italic.ttf',
  },
  Prompt: {
    normal: 'Prompt-Light.ttf',
    bold: 'Prompt-Regular.ttf',
    italics: 'Prompt-LightItalic.ttf',
    bolditalics: 'Prompt-Italic.ttf',
  },
};

const sumPrice = (sparePart) => _.sumBy(sparePart, (each) => each?.total_price);
const sumOuterServicePrice = (outerServices) =>
  _.sum(_.map(outerServices, (each) => each.price));

function printPDF(order, info, sparePart, me) {
  // console.log('order', order);
  // console.log('info', info);
  console.log('sparePart', sparePart);
  // console.log('price', order?.price);
  // console.log('me', me);

  const {
    setting: { cmms: cmmsSetting },
  } = info;

  const outerServices = order?.outer_services;

  const genMaterial = (sparePart) => {
    return _.map(sparePart, (eachSparePart, index) => [
      {
        text: index + 1,
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: `${eachSparePart?.material?.name}`,
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: cmmsSetting?.maintenance_report?.material_source ? 1 : 2,
      },
      cmmsSetting?.maintenance_report?.material_source
        ? {
            text: _.map(
              eachSparePart?.origin,
              (eachOrigin) =>
                `${eachOrigin?.material_stock_lot?.supplier?.name} ` || '',
            ),
            border: [true, false, true, true],
            fontSize: 11,
            colSpan: 1,
            alignment: 'center',
          }
        : {},
      {
        text: `${currencyFormater.format(eachSparePart?.amount)} ${
          eachSparePart?.material?.unit
        }`,
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: `${floatingCurrencyFormatter.format(
          eachSparePart?.average_price,
        )} `,
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: `${floatingCurrencyFormatter.format(eachSparePart?.total_price)}`,
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
        alignment: 'right',
      },
    ]);
  };

  const genTopic = (topic, cause) => {
    return _.map(topic, (_topic, index) => [
      {
        text: index + 1,
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: `${_topic?.topic?.name}`,
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
      },
      {
        text: '',
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: cmmsSetting?.maintenance_report?.topic_price ? 1 : 2,
        alignment: 'center',
      },
      cmmsSetting?.maintenance_report?.topic_price
        ? {
            text: floatingCurrencyFormatter.format(_topic?.price) || '',
            border: [true, false, true, true],
            fontSize: 11,
            colSpan: 1,
            alignment: 'center',
          }
        : {},
    ]);
  };

  const calculateTopicPrice = (topics) => {
    const topicPriceArr = _.map(topics, (_topic) => _topic?.price);
    return _.sum(topicPriceArr);
  };

  const genOuterServices = (outerservices) => {
    return _.map(outerservices, (_service, index) => [
      {
        text: index + 1,
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: `${_service?.title || '-'}`,
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
      },
      {
        text: `${_service?.garage?.name || '-'}`,
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
        alignment: 'center',
      },
      {
        text: floatingCurrencyFormatter.format(_service?.price || 0) || '-',
        border: [true, false, true, true],
        fontSize: 11,
        colSpan: 1,
        alignment: 'center',
      },
    ]);
  };

  const materialOrder = genMaterial(order?.process_materials);
  const maintenanceTopic = genTopic(order.maintenance_topic, order.cause);
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 150, 20, 230],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: 'รายงานการซ่อมรถ',
    },
    header: [
      {
        text: `${dayjs().format('DD/MM/BBBB')}`,
        alignment: 'right',
        margin: [10, 10, 10, 10],
        fontSize: '10',
      },
      {
        alignment: 'center',
        margin: [0, 0, 0, 12],
        text: [
          {
            text: `${info?.owner?.name}\n`,
            fontSize: '18',
            bold: true,
          },
          {
            text: `${info?.owner?.address?.house_number || ''} ${
              info?.owner?.address?.village_number
                ? `หมู่ที่${info?.owner?.address?.village_number}`
                : ''
            } ${info?.owner?.address?.road || ''} ${
              info?.owner?.address?.subdistrict || ''
            } ${info?.owner?.address?.district || ''} ${
              info?.owner?.address?.province || ''
            } ${info?.owner?.address?.postcode || ''}\n`,
            fontSize: '10',
          },
          {
            text: `โทร. ${info?.owner.telephone || '-'} แฟกซ์ ${
              info?.owner.fax || '-'
            }\n`,
            fontSize: '10',
          },
          {
            text: `อีเมล ${info?.owner?.email || '-'} เลขที่ผู้เสียภาษี ${
              info?.owner?.tax || '-'
            }\n`,
            fontSize: '10',
          },
        ],
      },
      {
        alignment: 'center',
        margin: [0, 4, 0, 8],
        text: 'รายงานการซ่อมรถ',
        fontSize: '16',
        bold: true,
      },
      {
        margin: [20, 0, 0, 0],
        canvas: [{ type: 'line', x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1 }],
      },
    ],
    // footer(currentPage, pageCount) {
    //   return [
    //     {
    //       text: `${currentPage.toString()} of ${pageCount}`,
    //       alignment: 'right',
    //       margin: [10, 10, 10, 10],
    //       fontSize: '10',
    //     },
    //   ];
    // },

    content: [
      cmmsSetting?.show_maintenance_type
        ? {
            alignment: 'center',
            margin: [0, 5, 0, 10],
            text: `${order?.maintenance_type?.name}`,
            fontSize: '14',
            bold: true,
          }
        : {
            text: '',
          },
      {
        style: 'tableExample',
        margin: [2, 0, 10, 2],
        table: {
          widths: ['25%', '30%', '15%', '30%'],
          body: [
            [
              {
                text: 'วัน-เดือน-ปีที่รับเข้าซ่อม',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(order?.start_date).format('D-MM-BBBB')}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'หมายเลขการซ่อม',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${order?.running_number || '-'} `,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'ประเภทรถ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${order?.vehicle?.type?.name || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'ป้ายทะเบียน',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${order?.vehicle?.licence_number || '-'} `,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'หางรถ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${order?.trailer_tail?.licence_number || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'ตู้คอนเทนเนอร์',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${order?.container?.licence_number || '-'} `,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'เลขไมล์',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${
                  order?.mileage
                    ? `${floatingCurrencyFormatter.format(order?.mileage)} km`
                    : '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'ชื่อพนักงานขับ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${order?.driver?.employee?.firstname || ''} ${
                  order?.driver?.employee?.lastname || ''
                } ${
                  order?.driver?.employee?.nickname
                    ? `(${order?.driver?.employee?.nickname})`
                    : ''
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'วันเดือนปีที่ซ่อมเสร็จ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${
                  order?.status !== MAINTENANCE_ORDER_STATUS.SUCCESS.status_code
                    ? '-'
                    : dayjs(order?.end_date).format('D-MM-BBBB')
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'อู่ซ่อม',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${order?.garage?.name || 'อู่ภายใน'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'สาเหตุ',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${order?.cause || ''}`,
                colSpan: 3,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {},
              {},
            ],
          ],
        },
      },
      !_.isEmpty(order?.maintenance_topic)
        ? {
            alignment: 'left',
            margin: [0, 10, 0, 0],
            text: 'รายการซ่อมบำรุง',
            fontSize: '12',
            bold: true,
          }
        : {},
      !_.isEmpty(order?.maintenance_topic)
        ? {
            margin: [0, 10, 0, 0],
            style: 'tableExample',
            layout: {
              fillColor(rowIndex, node, columnIndex) {
                return rowIndex === 0 ? '#c5cae9' : null;
              },
            },
            table: {
              widths: ['15%', '*', '25%', '20%'],
              heights: [15],
              headerRows: 1,
              body: [
                [
                  {
                    text: 'ลำดับ',
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                    text: 'รายการบำรุงรักษา',
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                    text: 'สาเหตุ',
                    colSpan: cmmsSetting?.maintenance_report?.topic_price
                      ? 1
                      : 2,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  cmmsSetting?.maintenance_report?.topic_price
                    ? {
                        text: 'ราคา',
                        border: [true, true, true, true],
                        fontSize: 11,
                        colSpan: 1,
                        alignment: 'center',
                      }
                    : {},
                ],
                ...maintenanceTopic,
                cmmsSetting?.maintenance_report?.topic_price
                  ? [
                      {
                        text: `( ${THBText(
                          calculateTopicPrice(order?.maintenance_topic),
                        )} )`,
                        colSpan: 2,
                        fontSize: 11,
                        alignment: 'center',
                        border: [true, true, true, true],
                      },
                      {},
                      {
                        text: 'รวมราคา',
                        colSpan: 1,
                        fontSize: 11,
                        bold: true,
                        alignment: 'center',
                        border: [true, true, true, true],
                      },
                      {
                        text:
                          floatingCurrencyFormatter.format(
                            calculateTopicPrice(order?.maintenance_topic),
                          ) || '0.00',
                        border: [true, true, true, true],
                        fontSize: 11,
                        colSpan: 1,
                        alignment: 'center',
                      },
                    ]
                  : [
                      {
                        text: '',
                        colSpan: 4,
                        border: [false, false, false, false],
                      },
                      {},
                      {},
                      {},
                    ],
              ],
            },
          }
        : {},
      !_.isEmpty(outerServices)
        ? {
            alignment: 'left',
            margin: [0, 10, 0, 0],
            text: 'การใช้บริการอู่ซ่อมภายนอก',
            fontSize: '12',
            bold: true,
          }
        : {},
      !_.isEmpty(outerServices)
        ? {
            margin: [0, 10, 5, 0],
            style: 'tableExample',
            layout: {
              fillColor(rowIndex, node, columnIndex) {
                return rowIndex === 0 ? '#c5cae9' : null;
              },
            },
            table: {
              widths: ['15%', '*', '25%', '20%'],
              headerRows: 1,
              body: [
                [
                  {
                    text: 'ลำดับ',
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                    text: 'รายการ',
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                    text: 'สถานที่',
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                    text: 'ราคา',
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                ],
                ...genOuterServices(outerServices),
                [
                  {
                    text: `( ${THBText(
                      sumOuterServicePrice(order.outer_services),
                    )} ) `,
                    colSpan: 2,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {},
                  {
                    text: 'รวมราคา',
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'center',
                    bold: true,
                    border: [true, true, true, true],
                  },
                  {
                    text: `${floatingCurrencyFormatter.format(
                      sumOuterServicePrice(order.outer_services) || 0,
                    )}`,
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                ],
              ],
            },
          }
        : {},
      !_.isEmpty(sparePart)
        ? {
            alignment: 'left',
            margin: [0, 10, 5, 5],
            text: 'รายการอะไหล่',
            fontSize: '12',
            bold: true,
          }
        : {},
      !_.isEmpty(sparePart)
        ? {
            margin: [0, 5, 0, 0],
            style: 'tableExample',
            layout: {
              fillColor(rowIndex, node, columnIndex) {
                return rowIndex === 0 ? '#c5cae9' : null;
              },
            },
            table: {
              widths: ['10%', '*', '15%', '10%', '10%', '10%'],
              headerRows: 1,
              body: [
                [
                  {
                    text: 'ลำดับ',
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                    text: 'รายการอะไหล่',
                    colSpan: cmmsSetting?.maintenance_report?.material_source
                      ? 1
                      : 2,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  cmmsSetting?.maintenance_report?.material_source
                    ? {
                        text: 'แหล่งที่มา',
                        colSpan: 1,
                        fontSize: 11,
                        alignment: 'center',
                        border: [true, true, true, true],
                      }
                    : {},
                  {
                    text: 'จำนวน',
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                    text: 'ราคา/หน่วย',
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                    text: 'ราคา',
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                ],
                ...materialOrder,
                [
                  {
                    text: ` ${
                      order?.price
                        ? `( ${THBText(sumPrice(sparePart))} )`
                        : '(ไม่มีการประเมินราคา)'
                    } `,
                    colSpan: 3,
                    fontSize: 11,
                    alignment: 'center',
                  },
                  {},
                  {},
                  {
                    text: 'รวมราคา',
                    fontSize: 11,
                    bold: true,
                    alignment: 'center',
                    colSpan: 2,
                  },
                  {},
                  {
                    text: `${floatingCurrencyFormatter.format(
                      sumPrice(sparePart),
                    )}`,
                    colSpan: 1,
                    fontSize: 11,
                    bold: true,
                    alignment: 'right',
                  },
                ],
              ],
            },
          }
        : {},
      cmmsSetting?.maintenance_report?.show_total_price
        ? {
            alignment: 'left',
            margin: [0, 10, 5, 5],
            text: 'ราคารวม',
            fontSize: '12',
            bold: true,
          }
        : {},
      cmmsSetting?.maintenance_report?.show_total_price
        ? {
            margin: [0, 5, 0, 0],
            style: 'tableExample',
            table: {
              widths: ['40%', '*', '20%'],
              headerRows: 1,
              body: [
                [
                  {
                    text: `${THBText(
                      sumPrice(sparePart) +
                        sumOuterServicePrice(order?.outer_services) +
                        calculateTopicPrice(order?.maintenance_topic),
                    )}`,
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'left',
                    bold: true,
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    fontSize: 11,
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  {
                    text: `${floatingCurrencyFormatter.format(
                      sumPrice(sparePart) +
                        sumOuterServicePrice(order?.outer_services) +
                        calculateTopicPrice(order?.maintenance_topic),
                    )}`,
                    colSpan: 1,
                    fontSize: 11,
                    alignment: 'right',
                    bold: true,
                    border: [false, false, false, false],
                  },
                ],
              ],
            },
          }
        : {},
    ],
    footer: [
      {
        margin: [0, 30, 0, 0],
        style: 'tableExample',
        table: {
          widths: ['33.3%', '33.3%', '33.3%'],
          heights: [15],
          body: [
            [
              {
                text: 'ลงชื่อ ...................................... ผู้แจ้ง',
                colSpan: 1,
                // bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: 'ลงชื่อ ...................................... ช่าง',
                colSpan: 1,
                // bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: 'ลงชื่อ ...................................... ผู้อนุมัติ',
                colSpan: 1,
                // bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '(......................................)',
                colSpan: 1,
                // bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: '(......................................)',
                colSpan: 1,
                // bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: '(......................................)',
                colSpan: 1,
                // bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [20, 20, 0, 0],
        fontSize: 11,
        columns: [
          {
            text: 'ความคิดเห็น ',
          },
        ],
      },
      {
        margin: [20, 4, 0, 20],
        fontSize: 11,
        columns: [
          {
            text: '................................................................................................................................................................................................\n................................................................................................................................................................................................\n................................................................................................................................................................................................\n................................................................................................................................................................................................ ',
          },
        ],
      },
      {
        margin: [20, 0, 0, 20],
        fontSize: 11,

        columns: [
          {
            text: `ผู้ทำรายการ ${me?.userData?.firstname}  ${me?.userData?.lastname}`,
            bold: true,
          },
          {
            text: 'ลงชื่อ ................................. ผู้รับรถหลังซ่อม',
            bold: true,
          },
        ],
      },
    ],
  };
  pdfMake.createPdf(docDefinition).open();
}

export default printPDF;
