import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { CSVUploadModal } from '../../components/Modal';
import { CONFIG } from '../../utils/constants';

function Material({ title, subtitle }) {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const information = useSelector((state) => state.information);
  const materialType = useSelector((state) => state.materialType);
  const place = useSelector((state) => state.place);
  const history = useHistory();
  const { control } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [selectMaterialType, setSelectMaterialType] = useState('');
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvWithClassifyOpen, setCsvWithClassifyOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const {
    setting: { ims: imsSetting },
  } = information;

  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  const fetchMaterial = () => {
    dispatch(
      actions.materialAll({
        name,
        page,
        size,
        selectMaterialType: selectMaterialType?._id,
        noStockLot: true,
        stockStatus: '',
      }),
    );
  };

  useEffect(() => {
    dispatch(actions.materialTypeAll(''));
    dispatch(actions.customerTypeAll({}));
    dispatch(actions.customerAll({}));
    dispatch(actions.placeAll({ page: 1, size: CONFIG.infiniteLimit }));

    return () => {};
  }, []);

  useEffect(() => {
    dispatch(actions.matrialReset());
    fetchMaterial();

    return () => {};
  }, [name, page, size, selectMaterialType]);

  useEffect(() => {
    setTotal(material?.total);
    return () => {};
  }, [material]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDeleteMaterial = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.materialDelete(id));
        fetchMaterial();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToDetailMaterial = async (id) => {
    history.push(`material/${id}`);
  };

  const handlePushToEditMaterial = async (id) => {
    history.push(`material/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      try {
        await dispatch(actions.materialCreate({ arr: csvData }));
        alert('สำเร็จ');
        handleCSVClose();
        fetchMaterial();
      } catch (error) {
        alert('เพิ่มวัสดุไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการวัสดุได้');
    }
  };

  const handleAddWithClassify = async () => {
    if (!_.isEmpty(csvData)) {
      try {
        await dispatch(
          actions.materialInsertWithClassification({ arr: csvData }),
        );
        alert('สำเร็จ');
        handleCSVClose();
        fetchMaterial();
      } catch (error) {
        alert('เพิ่มวัสดุไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการวัสดุได้');
    }
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button
        variant="contained"
        onClick={() => {
          history.push('materials/create');
        }}
      >
        เพิ่ม
      </Button>
      <Button variant="contained" onClick={handleCSVOpen} color="success">
        อัพโหลด
      </Button>
      <Button
        variant="contained"
        onClick={() => setCsvWithClassifyOpen(true)}
        color="info"
      >
        อัพโหลด และเพิ่มในคลังอัตโนมัติ
      </Button>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2">
          {!_.isEmpty(materialType?.rows) && (
            <Autocomplete
              options={materialType?.rows}
              getOptionLabel={(option) => option?.name || ''}
              onChange={(e, newValue) => setSelectMaterialType(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  label="ประเภทวัสดุ"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          )}
        </div>
      </div>
    </Card>
  );

  const renderCSVUploadModal = () => (
    <CSVUploadModal
      csvData={csvData}
      setCsvData={setCsvData}
      fileTemplateURL="/filetemplate/Material.csv"
      handleAddFromFile={handleAddFromFile}
      handleClose={handleCSVClose}
      isOpen={csvUploadOpen}
      titleName="วัสดุ"
      typeRows={materialType}
    />
  );

  const renderCSVAddWithClassify = () => (
    <CSVUploadModal
      csvData={csvData}
      setCsvData={setCsvData}
      fileTemplateURL="/filetemplate/MaterialWithClassify.csv"
      handleAddFromFile={handleAddWithClassify}
      handleClose={() => setCsvWithClassifyOpen(false)}
      isOpen={csvWithClassifyOpen}
      titleName="วัสดุ (แบบการเพิ่มล็อตวัสดุโดยอัตโนมัติ)"
      // otherNameForTypes="รายชื่อคลัง"
      // typeRows={place}
    />
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                {imsSetting?.attribute?.type_code && (
                  <TableCell>
                    <div className="font-bold"> รหัสวัสดุ</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold"> ชื่อวัสดุ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ประเภทวัสดุ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ปริมาณขั้นต่ำ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ปริมาณขั้นสูง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> หน่วย</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> อายุการใช้งาน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(material.rows) ? (
                material.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    {imsSetting?.attribute?.type_code && (
                      <TableCell>{`${row.type_code || '-'}`}</TableCell>
                    )}
                    <TableCell>{`${row.name}`}</TableCell>
                    <TableCell>{`${row.material_type?.name || '-'}`}</TableCell>
                    <TableCell>{`${row.minimum_amount || '-'}`}</TableCell>
                    <TableCell>{`${row.maximum_amount || '-'}`}</TableCell>{' '}
                    <TableCell>{`${row.unit || '-'}`}</TableCell>{' '}
                    <TableCell>
                      {row?.work_life ? `${row?.work_life} กิโลเมตร` : '-'}
                    </TableCell>{' '}
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          onClick={() => handlePushToDetailMaterial(row.id)}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditMaterial(row.id)}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteMaterial(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          //
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (materialType.isLoading) {
    return <Loading />;
  }
  if (!materialType.isLoading && materialType.isCompleted) {
    return (
      <div>
        {renderCSVUploadModal()}
        {renderCSVAddWithClassify()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={material?.message} />;
}

Material.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Material.defaultProps = {
  title: '',
  subtitle: '',
};

export default Material;
