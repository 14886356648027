import React, { useState, useEffect } from 'react';
import { Card, CardContent, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import RefuelHistoryTable from './RefuelHistoryTable';
import * as actions from '../../redux/actions';
import { currencyFormatter } from '../../utils/functions';

export default function RefuelHistory() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const { id } = useParams();
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicle);
  const refuelTransition = useSelector((state) => state.RefuelTransition);
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  useEffect(() => {
    dispatch(actions.refuelTransitionAll({ vehicle: id, page, size }));
    return () => {};
  }, [page, size]);
  useEffect(() => {
    setTotal(refuelTransition?.total);
    return () => {};
  }, [refuelTransition]);

  return (
    <div>
      <div>
        <Card>
          <CardContent>
            <div className="font-semibold">สรุปค่าน้ำมันทั้งหมด</div>
            <div className="flex flex-wrap">
              <div className="w-1/2 lg:w-1/4 my-2">
                <div>ค่าน้ำมันทั้งหมด</div>
                <div className="text-lg">
                  {currencyFormatter.format(
                    _.sumBy(refuelTransition?.rows, (e) => e.refuel.cost),
                  )}
                  บาท
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="py-2">
        <RefuelHistoryTable
          refuelTransition={refuelTransition?.rows}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          size={size}
          page={page}
          total={total}
        />
      </div>
    </div>
  );
}
