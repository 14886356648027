import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import { Grid as GridIcon, List as ListIcon } from 'react-feather';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { MaterialStockTable } from '../../components/Table';
import { EachMaterialStockCard } from '../../components/Card';
import MaterialStockReport from '../../components/Report/MaterialStockReport';
import api from '../../utils/functions/api';
import { CONFIG } from '../../utils/constants';
import ExportExcelHistory from '../../components/Excel/ExportExelHistory';
import Historymaterail from '../../utils/modelutils/Historymaterail';

function DetailMaterialStock({ title, subtitle }) {
  const dispatch = useDispatch();
  const place = useSelector((state) => state.place);
  const material = useSelector((state) => state.material);
  const materialType = useSelector((state) => state.materialType);
  const information = useSelector((state) => state.information);
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [includeEmptyStock, setIsIncludeEmptyStock] = useState(false);
  const [lowerAmountOnly, setIsLowerAmountOnly] = useState(false);
  const [upperAmountOnly, setIsUpperAmountOnly] = useState(false);
  const [selectedMaterialType, setSelectedMaterialType] = useState();
  const history = useHistory();
  const [displayMode, setDisplayMode] = useState('table');

  useEffect(() => {
    dispatch(actions.matrialReset());
    dispatch(actions.placeGet(id));
    dispatch(actions.materialTypeAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    console.log(selectedMaterialType?._id);
    dispatch(
      actions.materialAll({
        name,
        size,
        page,
        place: id,
        selectMaterialType: selectedMaterialType?._id || '',
        filterOutEmptyStock: !includeEmptyStock || '',
        lowerAmountOnly: lowerAmountOnly || '',
        upperAmountOnly: upperAmountOnly || '',
      }),
    );

    return () => {};
  }, [
    name,
    page,
    size,
    selectedMaterialType,
    includeEmptyStock,
    lowerAmountOnly,
    upperAmountOnly,
  ]);

  useEffect(() => {
    setTotal(material?.total);
    return () => {};
  }, [material]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => (
    <ViewTitle title={`${title}${place?.name || ''} `} subtitle={subtitle} />
  );

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeDisplayMode = (event, newDisplayMode) => {
    if (newDisplayMode !== null) {
      setDisplayMode(newDisplayMode);
    }
    if (newDisplayMode === 'card' && size < 25) {
      setSize(25);
      setPage(1);
    }
  };

  const handlePrintReport = async () => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/material?place=${
          id || ''
        }&page=1&size=${CONFIG.infiniteLimit}&lowerAmountOnly=${
          lowerAmountOnly || ''
        }&selectMaterialType=${
          selectedMaterialType?._id || ''
        }&filterOutEmptyStock=${!includeEmptyStock || ''}&status=IN_STOCK`,
      );
      if (status !== 200) {
        throw new Error('ไม่สามารถ Fetch ค่ามาได้');
      }

      MaterialStockReport(data?.rows, information, place);
    } catch (err) {
      alert(`ไม่สามารถพิมพ์รายงานได้ ${err?.message}`);
    }
  };

  const renderAddButtom = () => (
    <div className="flex flex-row gap-2 self-center justify-end ">
      <Button
        variant="contained"
        onClick={() => history.push(`/ims/material-stock/create/${id}`)}
      >
        เพิ่ม/ลด วัสดุ
      </Button>

      <Button variant="contained" color="secondary" onClick={handlePrintReport}>
        พิมพ์
      </Button>
      <div className="my-auto ">
        <ExportExcelHistory
          columnList={Historymaterail?.columns}
          currentData={material?.rows}
          dataAPIEndPoint="maintenance-order"
          disableAllExport
          dataQuery={{
            page: 1,
          }}
          sheetName="Material"
        />
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex  flex-wrap flex-row justify-between">
        <div className="w-full md:w-1/3">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-1/2 my-1 md:my-0 md:w-1/6 flex lg:justify-end gap-2">
          <Switch
            className="self-center"
            checked={includeEmptyStock}
            onChange={() => setIsIncludeEmptyStock(!includeEmptyStock)}
          />
          <div className="font-sansserif self-center">รวมรายการไม่มีสต็อก</div>
        </div>{' '}
        <div className="w-1/2 my-1 md:my-0 md:w-1/6 lg:justify-end ">
          <div className="flex gap-2">
            <Switch
              className="self-center"
              checked={lowerAmountOnly}
              onChange={() => {
                setIsUpperAmountOnly(false);
                setIsLowerAmountOnly(!lowerAmountOnly);
              }}
            />
            <div className="font-sansserif self-center">
              เฉพาะต่ำกว่า Stock Min
            </div>
          </div>
          <div className="flex gap-2">
            <Switch
              className="self-center"
              checked={upperAmountOnly}
              onChange={() => {
                setIsLowerAmountOnly(false);
                setIsUpperAmountOnly(!upperAmountOnly);
              }}
            />
            <div className="font-sansserif self-center">
              เฉพาะสูงกว่า Stock Max
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/6">
          <Autocomplete
            defaultValue={selectedMaterialType}
            options={materialType?.rows}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => {
              setSelectedMaterialType(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                size="small"
                label="ประเภทวัสดุ"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </div>
        <div className="w-full md:w-1/12 justify-end flex">
          <ToggleButtonGroup
            value={displayMode}
            exclusive
            onChange={handleChangeDisplayMode}
            aria-label="text alignment"
          >
            <ToggleButton value="table" aria-label="table">
              <ListIcon size={16} />
            </ToggleButton>
            <ToggleButton value="card" aria-label="card">
              <GridIcon size={16} />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <MaterialStockTable
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        material={material}
        page={page}
        placeId={id}
        size={size}
        total={total}
      />
    </div>
  );

  const renderCard = () => (
    <div className="my-2">
      <EachMaterialStockCard
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        material={material}
        page={page}
        placeId={id}
        size={size}
        total={total}
      />
    </div>
  );

  if (material?.isLoading) {
    return <Loading />;
  }
  if (!material?.isLoading && material?.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          {renderAddButtom()}
        </div>
        <div className="my-1">
          <BackButton />
        </div>
        {renderSearch()}
        {displayMode === 'table' ? renderTable() : renderCard()}
      </div>
    );
  }
  return <Error message={material?.message} />;
}

DetailMaterialStock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaterialStock.defaultProps = {
  title: '',
  subtitle: '',
};

export default DetailMaterialStock;
