import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Box,
  TextField,
  createFilterOptions,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';
import _ from 'lodash';

const AttachVehicleForm = ({
  control,
  resetField,
  watch,
  setValue,
  setDriverRendering,
  errors,
  vehicles,
  trailerTails,
  containers,
  handleCreateContainer,
}) => {
  const [addContainerOpen, setAddContainerOpen] = useState(false);
  const [dialogContainerName, setDialogContianerNumber] = useState('');
  const filter = createFilterOptions();

  const renderDialog = () => (
    <Dialog
      open={addContainerOpen}
      onClose={() => {
        setAddContainerOpen(false);
        setDialogContianerNumber('');
      }}
    >
      <DialogTitle>เพิ่มตู้คอนเทนเนอร์</DialogTitle>
      <DialogContent>
        <div className="py-2 mx-3 w-48">
          <TextField
            onChange={(event) => {
              setDialogContianerNumber(event.target.value);
            }}
            size="small"
            label="หมายเลขตู้คอนเทนเนอร์"
            fullWidth
            value={dialogContainerName}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleCreateContainer({ licence_number: dialogContainerName });
            setAddContainerOpen(false);
            setDialogContianerNumber('');
          }}
          variant="contained"
        >
          สร้าง
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div className="w-full flex flex-wrap">
      {renderDialog()}
      <div className="w-full px-1 py-2 pb-6 flex flex-wrap">
        <div className="w-full px-1 py-2 font-semibold text-lg">ส่วนของรถ</div>
        <div className="w-1/3 px-4 py-2">
          <FormControlLabel
            control={
              <Controller
                name={'enable_vehicle'}
                control={control}
                defaultValue={true}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      resetField('vehicle');
                      resetField('mileage');
                    }}
                    checked={field.value}
                  />
                )}
              />
            }
            label={'หัวรถ'}
          />
        </div>
        <div className="w-1/3 px-4 py-2">
          <FormControlLabel
            control={
              <Controller
                name={'enable_trailer_tail'}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      resetField('trailer_tail');
                    }}
                    checked={field.value}
                  />
                )}
              />
            }
            label={'หางรถ'}
          />
        </div>
        <div className="w-1/3 px-4 py-2">
          <FormControlLabel
            control={
              <Controller
                name={'enable_container'}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      if (e.target.checked === false) {
                        resetField('container');
                      }
                    }}
                    checked={field.value}
                  />
                )}
              />
            }
            label={'ตู้คอนเทนเนอร์'}
          />
        </div>
      </div>

      {watch('enable_vehicle') && !_.isEmpty(vehicles?.rows) && (
        <div className="w-1/2 px-1 py-2">
          <Controller
            name={'vehicle'}
            control={control}
            defaultValue={vehicles?.rows?.[0]}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                name={field.name}
                ref={field.ref}
                defaultValue={field.value}
                options={vehicles?.rows}
                autoHighlight
                getOptionLabel={(option) =>
                  `ป้ายทะเบียน ${option?.licence_number}`
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      fontSize: 15,
                      '& > span': { mr: '10px', fontSize: 18 },
                    }}
                    {...props}
                  >
                    {`ป้ายทะเบียน ${option?.licence_number}`}
                  </Box>
                )}
                onChange={(e, value) => {
                  field.onChange(value._id);
                  setValue('mileage', value.mileage);
                  setDriverRendering(false);
                  if (!value?.driver?._id) {
                    setValue('driver', undefined);
                  } else {
                    setValue('driver', value?.driver?.employee);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    label="รถ (หัวรถ)"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    helperText={errors.vehicle && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            )}
          />
        </div>
      )}
      {watch('enable_vehicle') && (
        <div className="w-1/2 px-1 py-2">
          <Controller
            name={'mileage'}
            control={control}
            defaultValue={0}
            render={({ field }) => (
              <TextField
                {...field}
                type={'number'}
                label="เลขไมล์ (หัวรถ)"
                fullWidth
                helperText={errors.mileage && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}
      {watch('enable_trailer_tail') && (
        <div className="w-full px-1 py-2">
          <Controller
            name={'trailer_tail'}
            control={control}
            defaultValue={undefined}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                disableClearable
                name={field.name}
                ref={field.ref}
                defaultValue={field.value}
                options={trailerTails?.rows}
                autoHighlight
                getOptionLabel={(option) =>
                  `ป้ายทะเบียน ${option?.licence_number}`
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      fontSize: 15,
                      '& > span': { mr: '10px', fontSize: 18 },
                    }}
                    {...props}
                  >
                    {`ป้ายทะเบียน ${option?.licence_number}`}
                  </Box>
                )}
                onChange={(e, value) => {
                  field.onChange(value._id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    label="หางรถ"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    helperText={errors.trailer_tail && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            )}
          />
        </div>
      )}
      {watch('enable_container') && !_.isEmpty(containers?.rows) && (
        <div className="w-full px-1 py-2">
          <Controller
            name={'container'}
            control={control}
            defaultValue={containers?.rows?.[0]}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                name={field.name}
                ref={field.ref}
                defaultValue={field.value}
                options={containers?.rows}
                autoHighlight
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option?.inputValue) {
                    return option?.inputValue;
                  }
                  return ` ${option?.licence_number}`;
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      fontSize: 15,
                      '& > span': { mr: '10px', fontSize: 18 },
                    }}
                    {...props}
                  >
                    {option?.licence_number}
                  </Box>
                )}
                onChange={(e, value) => {
                  if (typeof value === 'string') {
                    setTimeout(() => {
                      setAddContainerOpen(true);
                      setDialogContianerNumber(value);
                    });
                  } else if (value && value?.inputValue) {
                    setAddContainerOpen(true);
                    setDialogContianerNumber(value?.inputValue);
                  } else {
                    field.onChange(value._id);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    label="ตู้คอนเทนเนอร์"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    helperText={
                      errors.container && 'กรุณาใส่ข้อมูลตู้คอนเทนเนอร์'
                    }
                  />
                )}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (params?.inputValue !== '') {
                    filtered.push({
                      inputValue: params?.inputValue,
                      licence_number: `เพิ่ม "${params?.inputValue}"  ลงในระบบ`,
                    });
                  }

                  return filtered;
                }}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

AttachVehicleForm.propTypes = {
  control: PropTypes.object,
  resetField: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  setDriverRendering: PropTypes.func,
  errors: PropTypes.object,
  vehicles: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  trailerTails: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  containers: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  handleCreateContainer: PropTypes.func,
};

export default AttachVehicleForm;
