import api from '../../../utils/functions/api';
import {
  REFUELTRANSITION_ALL,
  REFUELTRANSITION_GET,
  REFUELTRANSITION_POST,
  REFUELTRANSITION_PUT,
  REFUELTRANSITION_DELETE,
  REFUELTRANSITION_LOADING,
  REFUELTRANSITION_ERROR,
} from '../types';
export const refuelTransitionCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: REFUELTRANSITION_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/refuel-transaction`,
      {
        ...payload,
      },
    );
    dispatch({ type: REFUELTRANSITION_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: REFUELTRANSITION_ERROR });
  }
};

export const refuelTransitionAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1, vehicle = '' } = params;

    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/refuel-transaction?name=${name}&size=${size}&page=${page}&vehicle=${vehicle}`,
    );
    if (status === 200) {
      dispatch({ type: REFUELTRANSITION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: REFUELTRANSITION_ERROR });
  }
};
export const refuelTransitionGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/refuel-transaction/${id}`,
    );
    if (status === 200) {
      dispatch({ type: REFUELTRANSITION_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: REFUELTRANSITION_ERROR });
  }
};

export const refuelTransitionPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: REFUELTRANSITION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/refuel-transaction/${id}`,
      payload,
    );
    dispatch({ type: REFUELTRANSITION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: REFUELTRANSITION_ERROR });
  }
};
export const refuelTransitionDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: REFUELTRANSITION_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/refuel-transaction/${id}`,
    );
    dispatch({ type: REFUELTRANSITION_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: REFUELTRANSITION_ERROR });
  }
};
