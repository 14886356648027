/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Card,
  TextField,
  InputAdornment,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import thLocale from 'date-fns/locale/th';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {
  MaterialHReport,
  DetailMaterialHTypeAddReport,
  DetailMaterialHTypeDescReport,
  TypeTwoMaintenancePickeUpOrderDicut,
} from 'components/Report/index';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { MaterialHistoryTable } from '../../components/Table';

import api from '../../utils/functions/api';
import ExportExcelHistory from '../../components/Excel/ExportExelHistory';
import HistoryTransaction from '../../utils/modelutils/HistoryTransaction';

function MaterialHistory({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialTransaction = useSelector((state) => state.materialTransaction);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const information = useSelector((state) => state.information);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [month, setMonth] = useState(new Date());
  const [monthChang, setMonthChang] = useState();
  const history = useHistory();
  const { control } = useForm();
  const [selectType, setSelectType] = useState('');
  const {
    setting: { ims: imsSetting },
  } = information;

  useEffect(() => {
    if (monthChang) {
      dispatch(
        actions.materialTransactionSearch({
          materialName: name,
          page,
          size,
          month: new Date(month).toISOString().split('T')[0],
          selectType,
        }),
      );
    } else {
      dispatch(
        actions.materialTransactionAll({
          materialName: name,
          page,
          size,
          selectType,
        }),
      );
    }
    dispatch(actions.materialTransactionTypeAll({}));
    dispatch(actions.informationAll());
    return () => {};
  }, [name, page, size, selectType, monthChang]);

  useEffect(() => {
    setTotal(materialTransaction?.total);
    return () => {};
  }, [materialTransaction]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handlePushToDetailMaterailTransaction = async (id) => {
    history.push(`/ims/history/detail/${id}`);
  };

  const handleSeachMaterialTransaction = () => {
    setMonthChang(month);
    dispatch(
      actions.materialTransactionSearch({
        materialName: name,
        page,
        size,
        month: new Date(month).toISOString().split('T')[0],
        selectType,
      }),
    );
  };

  const handleMaterialTransactionAll = async () => {
    try {
      if (monthChang) {
        const { data: materialTransactionNew } = await api.get(
          `${process.env.REACT_APP_API_URL}/material-transaction?month=${
            new Date(monthChang).toISOString().split('T')[0]
          }&selectType=${selectType}&materialName=${name}`,
        );
        return materialTransactionNew;
      }
      const { data: materialTransactionNew } = await api.get(
        `${process.env.REACT_APP_API_URL}/material-transaction?selectType=${selectType}&materialName=${name}`,
      );
      return materialTransactionNew;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handlePrintPdf = async () => {
    const materialTransactionNew = await handleMaterialTransactionAll();

    await MaterialHReport(
      materialTransactionNew?.rows,
      information,
      monthChang,
    );
  };

  const handlePrintPdfTypeAdd = async (row) => {
    await DetailMaterialHTypeAddReport(row, information);
  };
  const handlePrintPdfTypeTwo = async (row) => {
    await TypeTwoMaintenancePickeUpOrderDicut(row, information);
  };

  const handlePrintPdfTypeDesc = async (row) => {
    await DetailMaterialHTypeDescReport(row, information);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSelectType = (type) => {
    setSelectType(type);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button variant="contained" onClick={() => handlePrintPdf()}>
        พิมพ์
      </Button>
      <div className="my-auto ">
        <ExportExcelHistory
          columnList={HistoryTransaction?.columns}
          currentData={materialTransaction?.rows}
          dataAPIEndPoint="maintenance-order"
          disableAllExport
          dataQuery={{
            page: 1,
          }}
          sheetName="HistoryMaterial"
        />
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2">
          <Controller
            name={'status_code'}
            control={control}
            defaultValue={''}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  ค้นหาด้วยประเภท
                </InputLabel>
                <Select
                  {...field}
                  label="ค้นหาด้วยประเภท"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleSelectType(e.target.value);
                  }}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  {_.map(materialTransactionType.rows, (_type) => (
                    <MenuItem value={_type?._id} key={_type?._id}>
                      {_type?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="w-full  md:w-5/12 md:pl-1 my-2 md:my-0">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={thLocale}>
            <DatePicker
              views={['year', 'month']}
              label="เดือน"
              minDate={new Date('2012-03-01')}
              maxDate={new Date('2030-06-01')}
              value={month}
              onChange={(newValue) => {
                setMonth(newValue);
                setMonthChang(newValue);
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} required size={'small'} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="w-full md:w-1/12 ml-1 flex justify-items-center my-2 md:my-0">
          <Button
            variant="outlined"
            fullWidth
            onClick={() => handleSeachMaterialTransaction()}
          >
            ค้นหา
          </Button>
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <MaterialHistoryTable
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handlePrintPdfTypeAdd={handlePrintPdfTypeAdd}
        handlePrintPdfTypeDesc={handlePrintPdfTypeDesc}
        handlePrintPdfTypeTwo={handlePrintPdfTypeTwo}
        handlePushToDetailMaterailTransaction={
          handlePushToDetailMaterailTransaction
        }
        materialTransaction={materialTransaction}
        information={information}
        imsSetting={imsSetting}
        page={page}
        size={size}
        total={total}
      />
    </div>
  );

  if (materialTransaction.isLoading) {
    return <Loading />;
  }
  if (!materialTransaction.isLoading && materialTransaction.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>{' '}
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={materialTransaction?.message} />;
}

MaterialHistory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialHistory.defaultProps = {
  title: '',
  subtitle: '',
};

export default MaterialHistory;
