/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
} from '@mui/material';
import _ from 'lodash';
import { dayjs } from '../../../utils/functions';

const MaterialHistoryTable = ({
  imsSetting,
  materialTransaction,
  handlePushToDetailMaterailTransaction,
  handleChangeRowsPerPage,
  handlePrintPdfTypeTwo,
  page,
  size,
  total,
  handlePrintPdfTypeDesc,
  handlePrintPdfTypeAdd,
  handleChangePage,
  information,
}) => (
  <div>
    {' '}
    <Paper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <colgroup>
            <col width="5%" />
            <col width="10%" />
            <col width="5%" />
            <col width="5%" />
            {imsSetting?.display?.lot_number && <col width="10%" />}
            <col width="15%" />
            <col width="10%" />
            <col width="8%" />
            <col width="8%" />
            <col width="5%" />
            <col width="5%" />
            <col width="20%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> วันที่ดำเนินการ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ประเภท</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> เลขอ้างอิง</div>
              </TableCell>
              {imsSetting?.display?.lot_number && (
                <TableCell>
                  <div className="font-bold"> หมายเลขล็อต</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> วัสดุ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ชื่อคลัง</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> จำนวน</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> คงเหลือ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ผู้ดำเนินการ</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold"> รถ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> หมายเหตุ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> รายละเอียด</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(materialTransaction.rows) ? (
              materialTransaction.rows.map((row, index) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {(page - 1) * size + index + 1}
                  </TableCell>
                  <TableCell>
                    {dayjs(row?.createdAt).locale('th').format('DD MMM BBBB')}
                    <p>
                      เวลา {new Date(row?.createdAt).toLocaleTimeString('th')}{' '}
                      น.
                    </p>
                  </TableCell>
                  <TableCell>
                    {row?.material_transaction_type?.direction === 'add' ? (
                      <div className="w-32">
                        <div className="bg-gray-100 p-1 text-center rounded-md">
                          <p className="text-green-500 ">{`${row?.material_transaction_type?.name}`}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="w-32">
                        <div className="bg-gray-100 p-1 text-center rounded-md">
                          <p className="text-red-500 ">{`${row?.material_transaction_type?.name}`}</p>
                        </div>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <div>
                      {_.map(row?.material_stock_lot, (_material, _index) => (
                        <div key={_index}>
                          {_material?.lotId?.running_number || '-'}
                        </div>
                      ))}
                    </div>
                  </TableCell>

                  {imsSetting?.display?.lot_number && (
                    <TableCell>
                      <div>
                        {_.map(row?.material_stock_lot, (_material, _index) => (
                          <div key={_index}>
                            {_material?.lotId?.lot_number || '-'}
                          </div>
                        ))}
                      </div>
                    </TableCell>
                  )}
                  <TableCell>
                    {_.map(row?.material_stock_lot, (_material, _index) => (
                      <div key={_index}>
                        <span>{`${_index + 1}. `}</span>
                        <span className="font-sans font-bold">
                          {_material?.lotId?.material?.type_code}
                        </span>{' '}
                        <span>{_material?.lotId?.material?.name}</span>
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {_.map(row?.material_stock_lot, (lot, _index) => (
                      <div
                        className="text-blue-500 p-1 bg-gray-100 text-center rounded-md mb-1"
                        key={_index}
                      >
                        {lot?.lotId?.place?.name}
                      </div>
                    ))}
                  </TableCell>

                  <TableCell>
                    {' '}
                    {_.map(row?.material_stock_lot, (_material, _index) => (
                      <p key={_index}>
                        {_material?.quantity} {_material?.lotId?.material?.unit}
                      </p>
                    ))}
                  </TableCell>
                  <TableCell>
                    {' '}
                    {_.map(row?.material_stock_lot, (_material, _index) => (
                      <p key={_index}>
                        {_material?.amount} {_material?.lotId?.material?.unit}
                      </p>
                    ))}
                  </TableCell>
                  <TableCell>
                    {row?.material_transaction_type?.direction === 'add' ? (
                      <div>
                        <b>{`${row?.material_stock_lot[0]?.lotId?.recipient_name}`}</b>
                      </div>
                    ) : (
                      <div>
                        <b>{`${row?.request_name}`}</b>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    {row?.vehicle && <div>{row?.vehicle?.licence_number}</div>}
                    {row?.trailer_tail && (
                      <div>หางรถ {row?.trailer_tail?.licence_number}</div>
                    )}
                  </TableCell>
                  <TableCell>
                    {row?.remark}
                    {_.map(row?.material_stock_lot, (_material, _index) => (
                      <p key={_index}>{_material?.lotId?.remark}</p>
                    ))}
                  </TableCell>
                  <TableCell>
                    <div className="flex flex-wrap ">
                      <div className="mr-2 mb-2">
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          onClick={() => {
                            handlePushToDetailMaterailTransaction(row._id);
                          }}
                        >
                          รายละเอียด
                        </Button>
                      </div>

                      {information?.setting?.cmms?.available_report
                        ?.maintenance_pickeUp_order_report_2_dicut && (
                        <div className="mb-2">
                          <Button
                            variant="contained"
                            color="primary"
                            size={'small'}
                            onClick={() => {
                              if (
                                row?.material_transaction_type?.direction ===
                                'desc'
                              ) {
                                handlePrintPdfTypeDesc(row);
                              } else {
                                handlePrintPdfTypeTwo(row);
                              }
                            }}
                          >
                            พิมพ์เฉพาะตัวอักษร
                          </Button>
                        </div>
                      )}
                      <div className="mb-2">
                        <Button
                          variant="contained"
                          color="primary"
                          size={'small'}
                          onClick={() => {
                            if (
                              row?.material_transaction_type?.direction ===
                              'desc'
                            ) {
                              handlePrintPdfTypeDesc(row);
                            } else {
                              handlePrintPdfTypeAdd(row);
                            }
                          }}
                        >
                          พิมพ์
                        </Button>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page - 1}
        count={total || 1}
        rowsPerPage={size}
        onPageChange={handleChangePage}
      />
    </Paper>
  </div>
);

MaterialHistoryTable.propTypes = {
  imsSetting: PropTypes.object,
  materialTransaction: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  handlePushToDetailMaterailTransaction: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handlePrintPdfTypeDesc: PropTypes.func,
  handlePrintPdfTypeAdd: PropTypes.func,
  handleChangePage: PropTypes.func,
};

export default MaterialHistoryTable;
