/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import {
  Card,
  TextField,
  InputAdornment,
  Button,
  CardContent,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { ViewTitle } from '../../components/ViewTitle';
import { currencyFormatter } from '../../utils/functions';
import { BackButton } from '../../components/Button';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

export default function MonthyMaintenanceAnalysis({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const maintenanceOrder = useSelector((state) => state.maintenanceOrders);
  const information = useSelector((state) => state.information);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();

  useEffect(() => {
    dispatch(
      actions.maintenanceOrderAll({
        page,
        size,
        includeHistory: true,
        start: dayjs(id).startOf('month').format('YYYY-MM-DD'),
        end: dayjs(id).endOf('month').format('YYYY-MM-DD'),
      }),
    );
    return () => {};
  }, [page, size]);

  useEffect(() => {
    if (maintenanceOrder?.rows) {
      setTotal(maintenanceOrder?.total);
    }

    return () => {};
  }, [maintenanceOrder]);

  const renderTitle = () => (
    <ViewTitle
      title={`${title} ${dayjs(id).format('MMMM / BBBB')}`}
      subtitle={subtitle}
    />
  );

  const renderDashboardButton = () => (
    <div className="self-center">
      <Link to={`/cmms/maintenance-history/detail/${id}`}>
        <Button color="info" variant="contained">
          ประวัติการแจ้งบำรุงรักษา
        </Button>
      </Link>
    </div>
  );

  const renderCostDashboard = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="font-semibold text-lg">ค่าใช้จ่าย</div>
          <div className="my-2 flex flex-wrap">
            <div className="w-full lg:w-1/2 my-2">
              <div>จำนวนรายการทั้งหมด</div>
              <div className="text-3xl">
                {maintenanceOrder?.total}
                <span className="text-base ml-2">รายการ</span>
              </div>
            </div>
            <div className="w-full lg:w-1/2 my-2">
              <div>ราคารวม</div>
              <div className="text-3xl">
                {currencyFormatter.format(
                  _.sumBy(
                    maintenanceOrder?.analyzed,
                    (row) => row?.total_price,
                  ),
                )}
                <span className="text-base ml-2">บาท</span>
              </div>
            </div>
            <div className="w-full lg:w-1/3 my-2">
              <div>ค่าแรงในการบำรุงรักษา / ค่าบำรุงรักษา</div>
              <div className="text-3xl">
                {currencyFormatter.format(
                  _.sumBy(
                    maintenanceOrder?.analyzed,
                    (row) => row?.total_maintenance_topic,
                  ),
                )}
                <span className="text-base ml-2">บาท</span>
              </div>
              {/* <div className="my-2 text-indigo-800">
                คิดเป็น{' '}
                {currencyFormatter.format(
                  (maintenanceOrder?.analyzed?.total_maintenance_topic * 100) /
                    maintenanceOrder?.analyzed?.total_price,
                )}{' '}
                % ของทั้งหมด
              </div> */}
            </div>
            <div className="w-full lg:w-1/3 my-2">
              <div>ค่าอะไหล่</div>
              <div className="text-3xl ">
                {currencyFormatter.format(
                  _.sumBy(
                    maintenanceOrder?.analyzed,
                    (row) => row?.total_material_price,
                  ),
                )}
                <span className="text-base ml-2">บาท</span>
              </div>
              {/* <div className="my-2 text-indigo-800">
                คิดเป็น{' '}
                {currencyFormatter.format(
                  (maintenanceOrder?.analyzed?.total_material_price * 100) /
                    maintenanceOrder?.analyzed?.total_price,
                )}{' '}
                % ของทั้งหมด
              </div> */}
            </div>
            <div className="w-full lg:w-1/3 my-2">
              <div>ค่าแรงนอก</div>
              <div className="text-3xl">
                {currencyFormatter.format(
                  _.sumBy(
                    maintenanceOrder?.analyzed,
                    (row) => row?.total_outer_service_price,
                  ),
                )}
                <span className="text-base ml-2">บาท</span>
              </div>
              {/* <div className="my-2 text-indigo-800">
                คิดเป็น{' '}
                {currencyFormatter.format(
                  (maintenanceOrder?.analyzed?.total_outer_service_price *
                    100) /
                    maintenanceOrder?.analyzed?.total_price,
                )}{' '}
                % ของทั้งหมด
              </div> */}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  if (maintenanceOrder.isLoading) {
    return <Loading />;
  }

  if (!maintenanceOrder.isLoading && maintenanceOrder.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          {renderDashboardButton()}
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderCostDashboard()}
      </div>
    );
  }
}

MonthyMaintenanceAnalysis.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
