import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { MATERIAL_STOCK_STATUS } from '../../utils/constants';
import ExportExcelHistory from '../../components/Excel/ExportExelHistory';
import HistoryLot from '../../utils/modelutils/HistoryLot';

export default function ListMaterialStockLot({ title, subtitle }) {
  const dispatch = useDispatch();
  const place = useSelector((state) => state.place);
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [selectedStockStatus, setSelectedStockStatus] = useState('');

  const {
    setting: { ims: imsSetting },
  } = information;

  useEffect(() => {
    dispatch(
      actions.materialStockLotAll({
        materialName: name,
        size,
        page,
        stockStatus: selectedStockStatus,
        place: '',
      }),
    );
    return () => {};
  }, [name, page, size, selectedStockStatus]);

  useEffect(() => {
    setTotal(materialStockLot?.total);
    return () => {};
  }, [materialStockLot]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleDeleteMaterialStockLot = async (id) => {
    try {
      const confirm = window.confirm('ยืนยันการลบรายการล็อตวัสดุ');
      if (confirm) {
        await dispatch(actions.materialStockLotDelete(id));
        alert('สำเร็จ');
      }
    } catch (err) {
      console.error('Error on Delete ', err);
      alert(`ลบล็อตวัสดุไม่สำเร็จ ${err?.message}`);
    }
  };
  const renderExelButton = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <div className="">
        <ExportExcelHistory
          columnList={HistoryLot.columns}
          currentData={materialStockLot?.rows}
          dataAPIEndPoint="maintenance-order"
          disableAllExport
          dataQuery={{
            page: 1,
          }}
          sheetName="MaterialLot"
        />
      </div>
    </div>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row justify-between">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/4 px-1">
          <FormControl fullWidth>
            <InputLabel>สถานะสต็อก</InputLabel>
            <Select
              onChange={(event) => setSelectedStockStatus(event.target.value)}
              fullWidth
              size="small"
              label="สถานะสต็อก"
              placeholder="ทุุกสถานะ"
            >
              <MenuItem value="">ทุกสถานะ</MenuItem>
              {_.map(MATERIAL_STOCK_STATUS, (eachStatus, statusIndex) => (
                <MenuItem key={statusIndex} value={eachStatus.status_code}>
                  {eachStatus.status_code}
                </MenuItem>
              ))}
            </Select>{' '}
          </FormControl>
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รหัสอ้างอิงระบบ</div>
                </TableCell>
                {imsSetting?.display?.lot_number && (
                  <TableCell>
                    <div className="font-bold"> หมายเลขล็อต</div>
                  </TableCell>
                )}

                <TableCell>
                  <div className="font-bold"> วัสดุ</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold"> คู่ค้า</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold"> วันที่รับเข้าคลัง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> สถานะ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> คงเหลือ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(materialStockLot?.rows) ? (
                materialStockLot?.rows.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{row?.running_number || '-'}</TableCell>
                    {imsSetting?.display?.lot_number && (
                      <TableCell>{row?.lot_number || '-'}</TableCell>
                    )}

                    <TableCell>
                      {row?.material?.type_code || ''} {row?.material?.name}
                    </TableCell>

                    <TableCell>{row?.supplier?.name || '-'}</TableCell>

                    <TableCell>
                      <div>คลัง {row?.place?.name || '-'}</div>
                      วันที่ {dayjs(row?.receipt_date).format('D MMM BBBB')}
                    </TableCell>

                    <TableCell>
                      <div
                        className={`${
                          row?.status ===
                          MATERIAL_STOCK_STATUS.OUT_OF_STOCK.status_code
                            ? 'text-red-500 font-semibold'
                            : ''
                        }  `}
                      >
                        {row?.status}
                      </div>
                    </TableCell>
                    <TableCell>{row?.quantity}</TableCell>
                    <TableCell>{row?.amount}</TableCell>
                    <TableCell>
                      {' '}
                      <div className="flex flex-row flex-wrap gap-1">
                        {me?.userData?.role?.level >= 2 && (
                          <Link to={`/ims/material-stock-lot/edit/${row?._id}`}>
                            <Button
                              variant="contained"
                              color="warning"
                              size="small"
                            >
                              แก้ไข
                            </Button>
                          </Link>
                        )}
                        {me?.userData?.role?.level >= 2 && (
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() =>
                              handleDeleteMaterialStockLot(row?._id)
                            }
                          >
                            ลบ
                          </Button>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (materialStockLot?.isLoading) {
    return <Loading />;
  }
  if (!materialStockLot?.isLoading && materialStockLot?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between">
          <BackButton />
          <div className="flex justify-end">{renderExelButton()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={materialStockLot?.message} />;
}

ListMaterialStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ListMaterialStockLot.defaultProps = {
  title: '',
  subtitle: '',
};
